
import { ORDERS_ITEMS_ADD } from "./orderActionTypes";

const initialState = {side:[], cond:{}, next:[]};

export function orderReducer(state= initialState, action){
    switch(action.type){
        case ORDERS_ITEMS_ADD:
            return action.orders;

        default:
            return state;
    }
}