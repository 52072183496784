import { Link } from "react-router-dom";
import React from "react";

const Banklist = ({ banks }) => {
  return (
    <section
      id="categories-2"
      className="bg-whitesmoke wide-70 categories-section division"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-header">
              <h3 className="block-header__title">Our Banking Partners </h3>
              <div className="block-header__divider" />
            </div>
          </div>
        </div>
        <div className="row">
          {banks &&
            banks.map((bank) => (
              <div key={bank._id} className="col-sm-6 col-lg-3 col-xl-3">
                <Link to={`/site/bank/${bank._id}`}>
                  <div className="c2-box text-center">
                    {/* Icon */}
                    <img
                      className=""
                      src={
                        bank.images.length > 0
                          ? bank.images[0].url
                          : "images/bank1.jpg "
                      }
                      alt='bank'
                    />
                    {/* Text */}
                    <h5 className="h5-sm">{bank.bankName} </h5>
                   
                  </div>
                </Link>
              </div>
            ))}
        </div>

        <div className="row">
          <div className="section-title mb-60">
            <div className="title-btn">
              <Link to="/site/banks" className="btn btn-primary">
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banklist;
