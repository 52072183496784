import { COMPARE_ADD_ITEM, COMPARE_REMOVE_ITEM, COMPARE_EMPTY, COMPARE_TOGGLE } from './compareActionTypes';


function addItem(state, product) {
    const itemIndex = state.items.findIndex((x) => x._id === product._id);

    if (itemIndex === -1 && state.items.length < 4) {
        return {show:true, items:[
            ...state.items,
            JSON.parse(JSON.stringify(product)),
        ]};
    }

    return state;
}

function removeItem(state,productId){
    const items = state.items.filter((x) => x._id !== productId);
    console.log(items)
    return ({...state, items});
}



const initialState = {show:false, items:[]};

export default function compareReducer(state = initialState, action) {
    switch (action.type) {
    case COMPARE_ADD_ITEM:
        return addItem(state, action.product);    

    case COMPARE_REMOVE_ITEM:
        return removeItem(state, action.productId);

    case COMPARE_TOGGLE:
        return {...state,show:action.show}
    
    case COMPARE_EMPTY:
        return initialState;

    default:
        return state;
    }
}
