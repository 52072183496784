// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem, cartToggle } from '../../store/cart';
import { url } from '../../services/utils';
import checkoutApi from '../../api/checkout';

function IndicatorCart(props) {
    const { cart, cartRemoveItem, cartToggle, currency, auth, locale } = props;
    let dropdown;
    let totals;
    // const cartref = useRef();
    const history = useHistory();   
    

    const handleCheckout =(e) =>{
        e.preventDefault(); 
        let address={email:"",mobile:""};               
        checkoutApi.addToCart({...cart,currency,address}).then(
            result => {
                // console.log(result)
                // cartref.current.toggle() 
                cartToggle(false);               
                history.push('/shop/checkout');
            },
            )
        
    }

    if (cart.extraLines.length > 0) {
        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={extraLine.price} /></td>
            </tr>
        ));

        totals = (
            <React.Fragment>
                <tr>
                    <th>Subtotal</th>
                    <td><Currency value={cart.subtotal} /></td>
                </tr>
                {extraLines}
            </React.Fragment>
        );
    }

    const items = cart.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.product)} className="product-image__body">
                        <img className="product-image__img" src={item.product.images[0].url} alt="" />
                       
                    </Link>
                </div>
            );
        }

       

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.product)}>{item.product.title}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={item.price} /></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcar">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>Total</th>
                                <td><Currency value={cart.total} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart"
                    onClick={()=>cartToggle(false)} >View Cart</Link>
                    {auth.isLogin ?<button className="btn btn-primary" type='button'
                    onClick={handleCheckout} >Checkout</button> :
                    <button disabled className="btn btn-primary">Checkout</button> }
                    
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcar">
                <div className="dropcart__empty">
                    Your shopping cart is empty!
                </div>
            </div>
        );
    }

    return (<>
         {/* <Indicator ref={cartref} url="/shop/cart" dropdown={dropdown} value={cart.quantity} name1={"cart"} icon={<Cart20Svg />} /> */}
        <Indicator onClick={()=>cartToggle(true)} value={cart.quantity} name1={"cart"} icon={<Cart20Svg />} />

        <Offcanvas show={cart.show} onHide={()=>cartToggle(false)} placement={locale === 'ar' ? 'start' : 'end'}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {dropdown}
            </Offcanvas.Body>
      </Offcanvas>
        </>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    auth: state.auth,
    currency:state.currency,
    locale:state.locale,
});

const mapDispatchToProps = {
    cartRemoveItem, cartToggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
