import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";
import {authHeader} from '../_heleper/auth-header'

const bankApi = {
  getAllBanks: (limit,skip) => {
    return fetch(`${url}/bank/lists?limit=${limit}&skip=${skip}`).then(responseHandler);
  },

  getBank: (slug) => {
    return fetch(`${url}/bank/list/${slug}`).then(responseHandler);
  }, 
  
  getAllEvent: (limit,page,status) => {
    return fetch(`${url}/event/list?limit=${limit}&page=${page}&status=${status}`).then(responseHandler);
  },

  getEvent: (id) => {
    return fetch(`${url}/event/list/${id}`).then(responseHandler);
  }, 
  
  sendEnquiry: (data) => {   
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/mail/enquiry-mail`, reqOptions).then(responseHandler);
  },

  newsletter: (data) => {   
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/mail/news-letter`, reqOptions).then(responseHandler);
  },

};

export default bankApi;
