// react
import React, { useMemo,  } from "react";
import { Helmet } from "react-helmet-async";

import Homevideo from "../blocks/Homevideo";
import TestimonialSlider from "../blocks/TestimonialSlider";
import Homefaq from "../About/Homefaq";
import BlockBanner from "../blocks/BlockBanner";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockPosts from "../blocks/BlockPosts";
// import Blockcat from "../blocks/Blockcat";
import BlockProductColumns from "../blocks/BlockProductColumns";
// import BlockProducts from "../blocks/BlockProducts";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";
import CumtomSearch from "../Faqs/Faqs1";
import Demo4Banner2 from "../Demo1/Demo4Banner2";
import Demo3Categories2 from "../Demo1/Demo3Categories2";
import Banklist from "../Demo1/Demo3Categories3";
import Demo1Banner5 from "../Demo1/Demo1Banner5";

import theme from "../../data/theme";
import shopApi from "../../api/shop";
import zoneApi from "../../api/zone";
// import { commonApi } from "../../api/common";
import { useDeferredData, useProductColumns, useProductTabs, } from "../../services/hooks";
import CatHome from "../blocks/CatHome";

// import { products } from "./product";


function HomePageOne() {

  const categories = useDeferredData(()=>shopApi.getCatSearchList(),[])
  const banner = useDeferredData(()=>shopApi.getBannerList(),[])
    
  const featuredProducts = useProductTabs(
    useMemo(
      () => [
        // { id: 1, name: "Freezone", categorySlug: undefined },
        // { id: 2, name: "Freelance", categorySlug: undefined },        
        { id: 1, name: "Freezone", categorySlug: "638f1bd2b4c4cf5bef3943d6" },
        { id: 2, name: "Freelance", categorySlug: "638f1c02b4c4cf5bef3943da" },        
      ],
      []
    ),
    (tab) => shopApi.getPopularProducts({ limit: 12, category: tab.categorySlug })
  );

  
  // const featuredProducts = useProductTabs(
  //   useMemo(
  //     () => [
  //       { id: 1, name: "All", categorySlug: undefined },
  //       { id: 2, name: "Power Tools", categorySlug: "power-tools" },
  //       { id: 3, name: "Hand Tools", categorySlug: "hand-tools" },
  //       { id: 4, name: "Plumbing", categorySlug: "plumbing" },
  //     ],
  //     []
  //   ),
  //   (tab) => shopApi.getPopularProducts({ limit: 8, category: tab.categorySlug })
  // );

  const specialsellers = useDeferredData(() => shopApi.getSpecialProducts({ limit: 7 }), []);

  const vipdealssellers = useDeferredData(() => shopApi.getVipdealsProducts({ limit: 7 }), []);
  const blew5000sellers = useDeferredData(() => shopApi.getBelow5000Products({ limit: 7 }), []);
  const banklist = useDeferredData(() => shopApi.getBanklist({ limit: 7 }), []);

  const zonelist = useDeferredData(() => zoneApi.getAllZones(4, 0), []);

  // const ourmajorcategories = useDeferredData(() => shopApi.getMajorcategory({ limit: 7 }), []);
  
  const videosection = useDeferredData(() => shopApi.getVideosection({ limit: 7 }), []);
  const testimonialsection = useDeferredData(() => shopApi.getTestimonial({ limit: 7 }), []);
  const blogsection = useDeferredData(() => shopApi.getHomeblog({ limit: 7 }), []);
  const faqsection = useDeferredData(() => shopApi.getHomefaq({ limit: 7 }), []);

  const weeklydeal = useDeferredData(() => shopApi.getHomeweeklydeal({ limit: 7 }), []);
 

  const columns = useProductColumns(
    useMemo(
      () => [
        {
          title: "Top Rated Packages",
          source: () => shopApi.getSpecialProducts({ limit: 3 }),
        },
        {
          title: "Special Offers",
          source: () => shopApi.getSpecialProducts({ limit: 3 }),
        },
      ],
      []
    )
  );

  

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Home — ${theme.name}`}</title>
      </Helmet>
     
      {/* {useMemo(() => <Demo1Courses1 />, [])} */}
     
      {useMemo( () => ( <BlockSlideShow /> ), [])}
     

      {useMemo( () => ( <BlockFeatures /> ),[] )}
     
      {useMemo(
        () => (
          <BlockProductsCarousel tour={true}
            title="Best Selling Packages"
            subtitle="Save up to 50% Discount in business formation in UAE"
            layout="grid-4"
            products={featuredProducts.data}
            loading={featuredProducts.isLoading}
            groups={featuredProducts.tabs}
            onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [featuredProducts]
      )}
     

      {useMemo(  () => (  <BlockBanner name="AD01" data={banner.data} />   ), [banner] )}

      

    {/* our major category */}
      {useMemo( () => ( <CatHome data={categories.data} /> ), [categories.data] )}
      

      {/* {useMemo(() => <BlockPosts title="Latest News" layout="grid-nl" posts={posts} />, [])} */}
      {/* Demo1About4 */}
      {/* {useMemo(
        () => (
          <Blockcat
            title="Our Major Categories"
            layout="grid-nl"
            posts={ourmajorcategories.data}
          />
        ),
        [ourmajorcategories.data]
      )} */}
      {/* {useMemo(() => <Demo1Services6 />, [])} */}


      {useMemo(  () => (  <BlockBanner name="AD02" data={banner.data} />   ), [banner] )}
      
      
      {useMemo(
        () => (
          <BlockProductsCarousel
            title="Commonly ask "
            subtitle="Save up to 40% Discount in business formation in UAE "
            layout="grid-4"
            products={specialsellers.data.slice(0, 12)}
            loading={specialsellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [specialsellers]
      )}

     {/* deep search  */}
      {useMemo(
        () => (
          <CumtomSearch  data={categories.data}  />
        ),  // eslint-disable-next-line
        []
      )}{" "}

      {/* end tour */}
      
      
     


      {useMemo(
        () => (
          <BlockProductsCarousel
            title="Our Special  Deals"
            subtitle="Save up to 30% Discount in business formation in UAE"           
            layout="grid-4"
            products={specialsellers.data.slice(0, 12)}
            loading={specialsellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [specialsellers]
      )}

      
{useMemo(  () => (  <BlockBanner name="AD03" data={banner.data} />   ), [banner] )}

      
          {/*   weeklydeal   */}
          {useMemo(() => (
                <Demo4Banner2 weeklydeal={weeklydeal.data} />
            ), [weeklydeal.data])}
      
      <br></br>



    {useMemo(
        () => (
          <BlockProductsCarousel
            title="Food Stuff Trading Licenses"
            subtitle="Save up to 30% Discount in business formation in UAE"           
            layout="grid-4"
            products={vipdealssellers.data.slice(0, 12)}
            loading={vipdealssellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [vipdealssellers]
      )}

      
{useMemo(  () => (  <BlockBanner name="AD04" data={banner.data} />   ), [banner] )}
      

    {useMemo(
        () => (
          <BlockProductsCarousel
            title="Deals below 7000 AED "
            subtitle="Save up to 70% Discount in business formation in UAE"
            layout="grid-4"
            products={blew5000sellers.data.slice(0, 12)}
            loading={blew5000sellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [blew5000sellers]
      )}

    {useMemo(
        () => (
          <BlockProductsCarousel
            title="General trading Trade Licences"
            subtitle="Save up to 70% Discount in business formation in UAE"
            layout="grid-4"
            products={vipdealssellers.data.slice(0, 12)}
            loading={vipdealssellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [vipdealssellers]
      )}

{useMemo(  () => (  <BlockBanner name="AD05" data={banner.data} />   ), [banner] )}

{useMemo(
        () => (
          <BlockProductsCarousel
            title="IT constantly Trade Licenses"
            subtitle="Save up to 70% Discount in business formation in UAE"
            layout="grid-4"
            products={blew5000sellers.data.slice(0, 12)}
            loading={blew5000sellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [blew5000sellers]
      )}

{useMemo(
        () => (
          <BlockProductsCarousel
            title="Marketing Agency Trade Licence "
            subtitle="Save up to 70% Discount in business formation in UAE"
            layout="grid-4"
            products={specialsellers.data.slice(0, 12)}
            loading={specialsellers.isLoading}
            // groups={featuredProducts.tabs}
            // onGroupClick={featuredProducts.handleTabChange}
          /> // eslint-disable-next-line
        ),
        [specialsellers]
      )}

      {/* major partnership */}
      {useMemo(
        () => (
          <Demo3Categories2 zonelist={zonelist.data} />
        ),
        [zonelist.data]
      )}
      <br></br>

      

      {useMemo(
        () => (
          <Banklist banks={banklist.data.slice(0, 12)} />
        ),
        [banklist.data]
      )}
      <br></br>

      {/* {useMemo(() => <BlockBrands />, [])} */}
      {useMemo(
        () => (
          <BlockProductColumns columns={columns} />
        ),
        [columns]
      )}
      
      {useMemo(
        () => (
          <Demo1Banner5 />
        ),
        []
      )}

      <br></br>
      {useMemo(
        () => (
          <BlockPosts title="Our Blogs" layout="list-sm" posts={blogsection.data} />
        ),
        [blogsection.data]
      )}
      {/* {useMemo(() => <Demo3Categories4 />, [])} */}

      {useMemo(
        () => (
          <Homevideo title="Videos" layout="grid-nl" posts={videosection.data} />
        ),
        [videosection.data]
      )}
      {useMemo(
        () => (
          <TestimonialSlider title="Testimonial" layout="grid-nl" posts={testimonialsection.data} />
        ),
        [testimonialsection.data]
      )}
      
      {useMemo(
        () => (
          <Homefaq faq={faqsection.data.slice(0, 6)} />
        ),
        [faqsection.data]
      )}
      
      {/* {useMemo(() => (
                <BlockProductsCarousel
                    title="Featured Products"
                    layout="grid-4"
                    products={featuredProducts.data}
                    loading={featuredProducts.isLoading}
                    groups={featuredProducts.tabs}
                    onGroupClick={featuredProducts.handleTabChange}
                />
            ), [featuredProducts])}
           
            

            {useMemo(() => <BlockBanner />, [])}

            {useMemo(() => (
                <BlockProducts
                    title="Bestsellers"
                    layout="large-first"
                    featuredProduct={bestsellers.data[0]}
                    products={bestsellers.data.slice(1, 7)}
                />
            ), [bestsellers.data])}

            {useMemo(() => (
                <BlockCategories
                    title="Popular Categories"
                    layout="classic"
                    categories={categories}
                />
            ), [])}

            {useMemo(() => (
                <BlockProductsCarousel
                    title="New Arrivals"
                    layout="horizontal"
                    rows={2}
                    products={latestProducts.data}
                    loading={latestProducts.isLoading}
                    groups={latestProducts.tabs}
                    onGroupClick={latestProducts.handleTabChange}
                />
            ), [latestProducts])}

            {useMemo(() => <BlockPosts title="Latest News" layout="list-sm" posts={posts} />, [])}

            {useMemo(() => <BlockBrands />, [])}

            {useMemo(() => <BlockProductColumns columns={columns} />, [columns])} */}
    </React.Fragment>
  );
}

export default HomePageOne;
