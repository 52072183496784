import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";

const zoneApi = {
  getAllZones: (limit,skip) => {
    return fetch(`${url}/zone/lists?limit=${limit}&skip=${skip}`).then(responseHandler);
  },

  getZone: (slug) => {
    return fetch(`${url}/zone/list/${slug}`).then(responseHandler);
  },  

  getZoneProduct: (slug) => {
    return fetch(`${url}/product/zone-match/${slug}`).then(responseHandler);
  },

  getRelatedZone: (slug) => {
    return fetch(`${url}/zone/match-emirate/${slug}`).then(responseHandler);
  },  
  

};

export default zoneApi;
