// react
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import JoyrideProvider from "./tours/Joyride";

import * as serviceWorker from "./serviceWorker";
import Root from "./components/Root";
import store from "./store";

// styles
import "slick-carousel/slick/slick.css";
import "react-toastify/dist/ReactToastify.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-input-range/lib/css/index.css";
import "./scss/style.scss";



ReactDOM.render(
  <Provider store={store}>
    <Root /> 
    <JoyrideProvider/>    
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
