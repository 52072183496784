
import { Link } from "react-router-dom";
import React from "react";
const Demo3Categories2 = ({zonelist}) => {
    return (
        <section
            id="categories-2"
            className="bg-whitesmoke wide-70 categories-section division"
        >
            <div className="container">
                {/* SECTION TITLE */}
                <div className="row">
                    <div className="col-md-12">
                    <div className="block-header">
            <h3 className="block-header__title">Our Major Partnerships</h3>
            <div className="block-header__divider" />
            {/* {groupsList}
            {arrows} */}
        </div>
                       
                    </div>
                </div>
                <div className="row">
                   {zonelist.length >0 && zonelist.map((zone,idx)=>
                   <div className="col-sm-6 col-lg-3 col-xl-3" key={idx}>
                   <Link to={`/site/singlefreezone/${zone._id}`}>
                      
                           <div className="c2-box text-center">
                               {/* Icon */}
                               <img className="" src={zone.image && zone.image.url ? zone.image.url : "images/pa1.jpg"} alt='zone' />
                               {/* Text */}
                               <h5 className="h5-sm">{zone.label} </h5>
                               {/* <p>7 packages</p> */}
                               {/* <div className="course-rating clearfix">
           <i className="fas fa-star" />
           <i className="fas fa-star" />
           <i className="fas fa-star" />
           <i className="fas fa-star" />
           <i className="fas fa-star-half-alt" />
           <span >4.5 (26 Ratings)</span>
         </div> */}
                           </div>
                       
                   </Link>
               </div>
                   )}
                   
                </div>
                {/* End row */}
                <div className="row">
                <div className="section-title mb-60">
                        
                        {/* Title 	*/}
                        {/* <h4 className="">Our Major Partnerships </h4> */}
                        {/* Text */}
                        
                        {/* Button */}
                        <div className="title-btn">
                            <Link to="/site/freezone"
                                className="btn btn-primary">
                                    View More 
                                
                            </Link>
                        </div>
                    </div>
                    </div>
            </div>
            {/* End container */}
        </section>
    );
};

export default Demo3Categories2;
