
import { ADDON_ADD_ITEM, ADDON_REMOVE_ITEM,ADD_ADDIN_ADDON,REMOVE_ADDIN_ADDON } from './addonActionTypes';

export function addonsAddItem(product) {
    
    return {
        type: ADDON_ADD_ITEM,
        product,
    };
}

export function addonsRemoveItem(productId) {
    return {
        type: ADDON_REMOVE_ITEM,
        productId,
    };
}

export function addAdditionalAddon(product){
    return {type:ADD_ADDIN_ADDON, product}
}

export function removeAdditionalAddon(productId){
    return {type:REMOVE_ADDIN_ADDON, productId}
}
