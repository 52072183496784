import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Popup3 = ({ bonusPoint }) => {
  return (
    <section id="categories-2" className="categories-section division">
      <div className="">
        <div className="row">
          <Popup
            trigger={
              <span className="popup13">                
                Get bonus points 2000 <span className="popup12">Click Here</span>
              </span>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal model1">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header">Get bonus points</div>
                <div className="content" dangerouslySetInnerHTML={{__html:bonusPoint}} ></div>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </section>
  );
};

export default Popup3;
