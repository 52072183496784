export const percentage = (sellPrice, offerPrice) => {
    let discount = ((sellPrice - offerPrice) / sellPrice) * 100;
    return Math.floor(discount);
  };
  
  // daysLeft("2022-07-25")
 export const daysLeft = (date) => {
    let today = new Date();
    let end = new Date(date);
    let one_day = 1000 * 60 * 60 * 24;
    let days_left = Math.ceil((end.getTime() - today.getTime()) / one_day);
    if (days_left > 0) return (days_left + " days left at this price! ");
    else if (days_left === 0) return ("Today only this price");
    else return ("Offer end");
  };


  export const daysLeft2 = (date) => {
    let today = new Date();
    let end = new Date(date);
    let one_day = 1000 * 60 * 60 * 24;
    let days_left = Math.ceil((end.getTime() - today.getTime()) / one_day);
    if (days_left > 0) return days_left ;
    else if (days_left === 0) return 0;
    else return -1;
  };