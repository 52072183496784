import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";
import {authHeader} from '../_heleper/auth-header'

const checkoutApi = {

  placeOrder: (data) => {   
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/order/add`, reqOptions).then(responseHandler);
  },

  getAllVendorOrders: (page=1,limit=5) => {
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/order/vendor-list?page=${page}&limit=${limit}`,reqOptions).then(responseHandler);
  },

  getAllOrders: (page=1,limit=5) => {
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/order/list?page=${page}&limit=${limit}`,reqOptions).then(responseHandler);
  },
  getOrder:(id)=>{
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/order/view/${id}`,reqOptions).then(responseHandler);
  },

  updateOrder:(data,id,msg="msg=no")=>{
    const reqOptions = {
      method: "PUT",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),     
    };
    return fetch(`${url}/order/edit/${id}?${msg}`,reqOptions).then(responseHandler);
  },

  addToCart: (data) => {   
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/cart/add`, reqOptions).then(responseHandler);
  },
  
  getToCart: () => {   
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/cart/view`, reqOptions).then(responseHandler);
  },

  updateToCart: (data) => {   
    const reqOptions = {
      method: "PUT",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),     
    };
    return fetch(`${url}/cart/edit`, reqOptions).then(responseHandler);
  },

  getAddress: () => {   
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/address/list`, reqOptions).then(responseHandler);
  },

  viewAddress: (id) => {   
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/address/view/${id}`, reqOptions).then(responseHandler);
  },

  addAddress: (data) => {   
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/address/add`, reqOptions).then(responseHandler);
  },

  updateAddress: (data,id) => {   
    const reqOptions = {
      method: "PUT",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/address/edit/${id}`, reqOptions).then(responseHandler);
  },

  removeAddress: (id) => {   
    const reqOptions = {
      method: "DELETE",
      headers: {...authHeader(), "Content-Type": "application/json" },     
    };
    return fetch(`${url}/address/rm/${id}`, reqOptions).then(responseHandler);
  },  

  createPaymentIntent: (data) => {
    console.log(data)
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/payment/make-payment`, reqOptions).then(responseHandler);
  },

  createPreApprovalPayment: (data) => {
    console.log(data)
    const reqOptions = {
      method: "POST",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(`${url}/payment/pre-approval`, reqOptions).then(responseHandler);
  },

};

export default checkoutApi;
