// import Link from "next/link";
import React from "react";

const CategoriesListCategories4  = ({post}) => {
    return (
        <section
            id="categories-2"
            className="categories-section division"
        >
            <div className="">
                <div className="row">
                    {/* CATEGORIE BOX #1 */}
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                    <iframe width="100%" className="video" height="250" src={post.url} title="YouTube video player" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
           
                    </div>
                    {/* END CATEGORIE BOX #1 */}
                    
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default CategoriesListCategories4;
