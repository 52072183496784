// react
import React, { useState } from "react";
// import Faqs5 from "../Faqs/Faqs5";
import "reactjs-popup/dist/index.css";
// import Currency from "../shared/Currency";

function HomePageOne({ product, offerPrice, isLogin, validity }) {
  // const contractOption = product.length ? product : [];
  // let defItem = contractOption.length && contractOption[0];
  // let defPrice = offerPrice;

  // const [, setContract] = useState(defItem);
  // const [, setPrice] = useState(defPrice);
  const [active, setActive] = useState(1);
  const activeLi = (value) => (active === value ? "current" : "");

  // const handleContract = (e) => {
  //   let item = contractOption.find((item) => item.value.toString() === e.target.value);

  //   if (item) {
  //     let discountPrice = defPrice - defPrice * (item.discount / 100);
  //     setContract(item);
  //     setPrice(discountPrice);
  //   }
  // };

  return (
    <React.Fragment>
      <section id="faqs-1" className=" faq5 faqs-section division block">
        <div className="">
          <div className="row">
            {/* TABS NAVIGATION */}
            <div className="col-lg-12 head-2">
              <div className="tabs-nav tabs-nav1 fq2 clearfix">
                <ul className="tabs-1 tabs-2 faq4">
                  {/* TAB-1 LINK */}
                  {/* <li
                  className={`tab-link ${activeLi(1)}`}
                  onClick={() => setActive(1)}
                  data-tab="tab-1"
                >
                 
                  <div className="tab-link-txt tab-link-txt1">
                    <h5 className="h5-xs">Full Payment</h5>
                    
                  </div>
                </li> */}
                  {/* TAB-2 LINK */}
                  <li className={`tab-link ${activeLi(1)}`} onClick={() => setActive(1)} data-tab="tab-2">
                    <div className="tab-link-txt tab-link-txt1">
                      <h5 className="h5-xs">Liscense Validity</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="tabs-content">
                <div id="tab-2" className={`tab-content ${activeLi(1)}`}>
                  <div className="container">
                    <div className="row">
                      <div className="eminew">
                        <ul className="contractv">
                          {/* {contractOption.map((con, idx) => {
                            if (idx === 0)
                              return (
                                <li key={con.value}>
                                  <input
                                    type="radio"
                                    value={con.value}
                                    defaultChecked
                                    name="contract"
                                    onChange={handleContract}
                                  />
                                  <label htmlFor="">{con.value} Year</label>
                                </li>
                              );
                            else
                              return (
                                <li key={con.value}>
                                  <input type="radio" value={con.value} name="contract" onChange={handleContract} />
                                  <label htmlFor="">{con.value} Year</label>
                                </li>
                              );
                          })} */}
                          <li >
                                  <input
                                    type="radio"                                    
                                    defaultChecked                                    
                                  />
                                  <label htmlFor="" className="ml-2">{validity} Year</label>
                                </li>
                        </ul>

                        {/* <hr></hr>
                        {isLogin ? (
                          <div>
                            <p>Package Amount</p>
                            <p>
                              <Currency value={price} />
                              <small> + VAT</small>
                            </p>
                          </div>
                        ) : (
                          <p>Not Available</p>
                        )} */}
                      </div>

                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default HomePageOne;
