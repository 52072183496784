const brandsData = [
    { name: 'Freelance', slug: 'Freelance', image: 'assets/images/logos/logo-1.png' },
    { name: 'Freelance', slug: 'wakita', image: 'assets/images/logos/logo-2.png' },
    { name: 'Freelance', slug: 'zosch', image: 'assets/images/logos/logo-3.png' },
    { name: 'Freelance', slug: 'wevalt', image: 'assets/images/logos/logo-4.png' },
    { name: 'Freelance', slug: 'hammer', image: 'assets/images/logos/logo-5.png' },
    { name: 'Freelance', slug: 'mitasia', image: 'assets/images/logos/logo-6.png' },
    { name: 'Freelance', slug: 'metaggo', image: 'assets/images/logos/logo-7.png' },
];

export default brandsData;
