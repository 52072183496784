import { LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_FAILURE, 
  LOGOUT_SUCCESS, CHANGE_MSG_COUNT } from "./userActionTypes";

const initialState = {
  isLoading: false,
  isLogin: null,
  error: null,
};

const changeCount = (state) =>{
    const {isLoading, error, isLogin} = state;
    isLogin.user.msgcount = 0;
    
  return {isLoading,error,isLogin}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
      return { ...state, isLogin: action.user, isLoading: false };
    case LOGIN_FAILURE:
      return { ...state, isLoading: false };
    case LOGOUT_SUCCESS:
      return {isLoading:false, isLogin:null, error:null}
    case CHANGE_MSG_COUNT:
        return changeCount(state)
    default:
      return state;
  }
}
