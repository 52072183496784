

import { CART_ADD_ITEM, CART_ADD_ITEM_WITH_ADDON, 
    CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_UPDATE_QUANTITY,
    CART_REMOVE_ADDON_ITEM, CART_EMPTY, CART_TOGGLE} from './cartActionTypes';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */

// function findItemIndex(items, product, options) {
//     return items.findIndex((item) => {
//         if (item.product._id !== product._id || item.options.length !== options.length) {
//             return false;
//         }

//         for (let i = 0; i < options.length; i += 1) {
//             const option = options[i];
//             const itemOption = item.options.find((itemOption) => (
//                 itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
//             ));

//             if (!itemOption) {
//                 return false;
//             }
//         }

//         return true;
//     });
// }

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}



function addItem(state, product, options, quantity, pack) {
        
    let newItems = [], emirate = null, zone = null, category=null; 
    let newItem = {
            id: product._id,           
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.offerPrice,
            total: product.offerPrice * quantity,
            quantity,
            pack:product.pack ?? pack
        };
    if(pack === 'main' || pack === 'custom'){
         newItems =[newItem];
         emirate = product.emirate;
         zone = product.zone;
         category = product.category;
    }
    else {
        const index = state.items.findIndex(it=>it.id === newItem.id);       
        if(index === -1)
            newItems = [...state.items, newItem];
        else{
            newItems = [...state.items]
            const temp = newItems[index];
            const qty1 = temp.quantity + 1;
            const total1 = temp.price * qty1;
            newItems[index].quantity = qty1;
            newItems[index].total = total1;
        }
    } 

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state, subtotal, total, items: newItems,
        quantity: calcQuantity(newItems),
        pack, emirate, zone,category,
        show:pack==='addon'?false:true,        
    };
}

function addItemsWithAddon(state, products, options, quantity,pack){    
   
    let newItems = [], emirate = null, zone=null, category=null;
    

    products.forEach(product => {
        const item = {
            id: product._id,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.offerPrice,
            total: product.offerPrice * quantity,
            quantity,
            pack:product.pack ?? pack
        } 
        newItems.push(item);
        if(item.pack === 'main' || item.pack === 'custom'){
            emirate = product.emirate;
            zone = product.zone;
            category = product.category;            
        }
    });

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state, subtotal,  total,  items: newItems,
        quantity: calcQuantity(newItems),
        pack, emirate, zone,category, show:true       
    };
}

function updateQuantity(state, updateItem){
    
    const itemIndex = state.items.findIndex(item => item.id === updateItem.id)
    const item = state.items[itemIndex]

     let newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: updateItem.quantity,
                total: updateItem.quantity * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);
    
    return {
            ...state,  subtotal, total,  items: newItems,
            quantity: calcQuantity(newItems),
        };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function removeAddonItem(state, itemId){
    const { items } = state;
    const newItems = items.filter((item) => item.product._id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };

}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}

/*
* item example:
* {
*   id: 1,
*   product: {...}
*   options: [
*     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
*   ],
*   price: 250,
*   quantity: 2,
*   total: 500
* }
* extraLine example:
* {
*   type: 'fees',
*   title: 'Fees',
*   price: 26
* }
*/
const initialState = {    
    quantity: 0,    items: [],    owners:[],
    shareHolder:1,    subtotal: 0,    total: 0,
    pack:'main', show:false,   
    emirate:null,    zone:null,     category:null, 
    extraLines: [{
        type: 'fees', title: 'Service Charge',  price: 0,
    },
    {
        type: 'tax', title: 'Tax',  price: 0,
    }],      
};
  

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
    case CART_ADD_ITEM:
        return addItem(state, action.product, action.options, action.quantity, action.pack);

    case CART_ADD_ITEM_WITH_ADDON:
            return addItemsWithAddon(state, action.product, action.options, action.quantity, action.pack);

    case CART_REMOVE_ITEM:
        return removeItem(state, action.itemId);

    case CART_REMOVE_ADDON_ITEM:
        return removeAddonItem(state, action.itemId);

    case CART_UPDATE_QUANTITIES:
        return updateQuantities(state, action.quantities);

    case CART_UPDATE_QUANTITY:
        return updateQuantity(state, action.item);

    case CART_TOGGLE:
        return {...state,show:action.show}

    case CART_EMPTY:
        return initialState;

    default:
        return state;
    }
}
