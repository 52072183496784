import { CURRENCY_CHANGE } from './currencyActionTypes';
import { toast } from 'react-toastify';
import { fixerUrl } from '../../api/apiurl';

// eslint-disable-next-line import/prefer-default-export
// export const currencyChange = (currency) =>({ type: CURRENCY_CHANGE,currency})



export const currencyChange = (prev) =>(dispatch)=>{
    
    fetch(fixerUrl).then(res => res.json()).then(data => {
        if(data.success){
            // console.log(prev,data)
            const currency = {...prev, rate:data.rates[prev.code]||1}
            if(prev.code === "AED") currency.rate = 1.00
            return dispatch({ type: CURRENCY_CHANGE,currency})            
        }
        else toast.error('Not Available', { theme: 'colored' });
    }).catch(e =>{
        toast.error('Not Available', { theme: 'colored' });
    })    
}


