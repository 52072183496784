// deep search in home page

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import searchApi from "../../api/search";
import shopApi from "../../api/shop";
import { commonApi } from "../../api/common";
import { searchAddItem } from "../../store/search/searchActions";

const CustomSearch = () => {
  const [filters, setFilters] = useState({cat: "", emi: "", lic: "",sub: "",subgroup: "",actgroup: "",act: "",subact: "",visa: "", search: "",});
  const [categories, setCategories] = useState([]);
  const [emirates, setEmirates] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [subLicenses, setSubLicenses] = useState([]);
  const [subLicenseGroups, setSubLicenseGroups] = useState([]);
  const [activityGroups, setActivityGroups] = useState([]);
  const [activities, setActivites] = useState([]);
  const [subActivities, setSubActivities] = useState([]);

  const [active, setActive] = useState('fz');
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    shopApi.getCatSearchList().then((res) => {
        setCategories(res.categories);
        setEmirates(res.emirates);
        setFilters((f) => ({ ...f, cat: res.categories[0]._id }));
      })
      .finally(() => setLoading(false));
  }, []);

  const activeLive = (value) => (active === value ? "current" : "");

  const handleCatChange = (catId, value) => {
    setActive(value);
    
    searchApi.getEmirates(catId).then((res) => {
      // console.log(res)
      setEmirates(res);      
      setFilters((f) => ({
        ...f, cat: catId, emi: "", lic: "", sub: "", subgroup: "",
        actgroup: "", act: "", subact: "", visa: "",
      }));
     
    });
  };

  const handleEmiChange = (ev) => {
    const emiId = ev.target.value;
    setLoader({emi:true})
    emiId &&
      commonApi.getLicenses({ emirate: emiId }).then((res) => {
        setLicenses(res);
        setFilters((f) => ({
          ...f,  emi: emiId, lic: "", sub: "", subgroup: "", actgroup: "", act: "", subact: "", visa: "",
        }));
        setLoader({emi:false})
        // console.log(res)
      });
  };

  const handleFlEmiChange = (ev) => {
    const emiId = ev.target.value;
    setLoader({emi:true})
    emiId &&
      commonApi.getSubLicenses({ emirate: emiId, zone: [],category: filters.cat, }).then((res) => {
        setSubLicenses(res);
        setFilters((f) => ({
          ...f,  emi: emiId, lic: "", sub: "", subgroup: "", actgroup: "", act: "", subact: "", visa: "",
        }));
        setLoader({emi:false})
        // console.log(res)
      });
  };

  const handleLicChange = (ev) => {
    const licId = ev.target.value;
    setLoader({lic:true})
    const req = { category: filters.cat, emirate: filters.emi, zone: [], license: licId, };
    licId &&
      commonApi.getSubLicenses(req, "active").then((res) => {
        setSubLicenses(res);
        setFilters((f) => ({
          ...f, lic: licId, sub: "", subgroup: "", actgroup: "", act: "", subact: "", visa: "",
        }));
        setLoader({lic:false})
        // console.log(res)
      });
  };

  const handleSubLicChange = (ev) => {
    const subId = ev.target.value;
    setLoader({sub:true})
    const req = {
      category: filters.cat,
      emirate: filters.emi,
      zone: [],
      license: filters.lic,
      subLicense: subId,
    };
    subId &&
      commonApi.getSubLicenseGroups(req, "active").then((res) => {
        setSubLicenseGroups(res);
        setFilters((f) => ({
          ...f,
          sub: subId,
          subgroup: "",
          actgroup: "",
          act: "",
          subact: "",
          visa: "",
        }));
        setLoader({sub:false})
        // console.log(res)
      });
  };

  const handleFlSubLicChange = (ev) => {
    const subId = ev.target.value;
    setLoader({sub:true})
    const req = {category: filters.cat, emirate: filters.emi,  zone: [],  subLicense: subId,  };
    subId &&
      commonApi.getSubActivities(req).then((res) => {
        setSubActivities(res);
        setFilters((f) => ({
          ...f,  sub: subId, subgroup: "",  actgroup: "", act: "",  subact: "",   visa: "",
        }));
        setLoader({sub:false})
        console.log(res)
      });
  };

  const handleSubLicGroupChange = (ev) => {
    const subgId = ev.target.value;
    setLoader({subgroup:true})
    const req = {
      category: filters.cat,
      emirate: filters.emi,
      zone: [],
      license: filters.lic,
      subLicense: filters.sub,
      subLicenseGroup: subgId,
    };
    subgId &&
      commonApi.getActivityGroups(req, "active").then((res) => {
        setActivityGroups(res);
        setFilters((f) => ({
          ...f,
          subgroup: subgId,
          actgroup: "",
          act: "",
          subact: "",
          visa: "",
        }));
        setLoader({subgroup:false})
        // console.log(res)
      });
  };

  const handleActGroupChange = (ev) => {
    const actgId = ev.target.value;
    setLoader({actgroup:true})
    const req = {
      category: filters.cat,
      emirate: filters.emi,
      zone: [],
      license: filters.lic,
      subLicense: filters.sub,
      subLicenseGroup: filters.subgroup,
      activityGroup: actgId,
    };
    actgId &&
      commonApi.getActivities(req, "active").then((res) => {
        setActivites(res);
        setFilters((f) => ({
          ...f,
          actgroup: actgId,
          act: "",
          subact: "",
          visa: "",
        }));
        setLoader({actgroup:false})
        // console.log(res)
      });
  };

  const handleActChange = (ev) => {
    const actId = ev.target.value;
    setLoader({act:true})
    const req = {
      category: filters.cat,
      emirate: filters.emi,
      zone: [],
      license: filters.lic,
      subLicense: filters.sub,
      subLicenseGroup: filters.subgroup,
      activityGroup: filters.actgroup,
      activity: actId,
    };
    actId &&
      commonApi.getSubActivities(req, "active").then((res) => {
        setSubActivities(res);
        setFilters((f) => ({ ...f, act: actId, subact: "", visa: "" }));
        setLoader({act:true})
        // console.log(res);
      });
  };

  const handleSubActChange = (ev) => {
    const subactId = ev.target.value;
    console.log(subactId);
    setFilters((f) => ({ ...f, subact: subactId, visa: "" }));
  };

  const handleVisaChange = (ev) => {
    const visa = ev.target.value;
    setFilters((f) => ({ ...f, visa }));
  };

  const onSearchInputChange = (ev) => {
    setFilters((f) => ({ ...f, search: ev.target.value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // console.log(filters)
    dispatch(searchAddItem(filters));
    history.push("/package/catalog/custom");
  };

  if (loading) return null;

  return (
    <section id="faqs-1" className="bg-01 wide-70 faqs-section division block">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="tabs-nav clearfix">
              <ul className="tabs-1">
                {categories.length > 0 &&
                  categories.map((item, idx) => (
                    <li
                      key={item._id}
                      className={`tab-link ${activeLive(item.value)}`}
                      onClick={() => handleCatChange(item._id, item.value)}
                      data-tab="tab-1"
                    >
                      <div className="tab-link-txt">
                        <h5 className="h5-xs">{item.label}</h5>
                      </div>
                    </li>
                  ))}
                {/* <li className={`tab-link `}  data-tab="tab-4">
                  <div className="tab-link-txt">
                    <h5 className="h5-xs">Offshore</h5>
                  </div>
                </li>
                <li className={`tab-link `}  data-tab="tab-4">
                  <div className="tab-link-txt">
                    <h5 className="h5-xs">Services</h5>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="tabs-content">
              <div id="tab-1" className={`tab-content current`}>
                <div  className="container ">
                  <div className="row">
                    {/* <div className="col-lg-12 offset-lg-1"> */}
                    <div className="col-lg-12 ">
                      <div className="hero-txt text-center white-color">
                        <br></br>
                        <form className="search__form1" action="">
                          
                          {active === 'fz' && <>

                          <select className="search__categories1" name="emi" value={filters.emi} onChange={handleEmiChange}>
                            <option value="" disabled>
                              {loader.emi ? "Loading..." : "Emirates"}
                            </option>
                            {emirates &&
                              emirates.length > 0 &&
                              emirates.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select>

                          <select className="search__categories1" name="lic" value={filters.lic} onChange={handleLicChange}>
                            <option value="" disabled>
                            {loader.lic ? "Loading..." : "Licenses"}
                            </option>
                            {licenses &&
                              licenses.length > 0 &&
                              licenses.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select>

                          <select className="search__categories1" name="sub" value={filters.sub} onChange={handleSubLicChange}>
                            <option value="" disabled>
                            {loader.sub ? "Loading..." : "Main Activity Group"}
                            </option>
                            {subLicenses &&
                              subLicenses.length > 0 &&
                              subLicenses.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select>

                          <select
                            className="search__categories1"
                            name="subgroup"
                            value={filters.subgroup}
                            onChange={handleSubLicGroupChange}
                          >
                            <option value="" disabled>
                            {loader.subgroup ? "Loading..." : "Activity Sub Group"}
                            </option>
                            {subLicenseGroups.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.label}
                              </option>
                            ))}
                          </select>

                          <select
                            className="search__categories1"
                            name="actgroup"
                            value={filters.actgroup}
                            onChange={handleActGroupChange}
                          >
                            <option value="" disabled>
                            {loader.actgroup ? "Loading..." : "Activity Group"}
                            </option>
                            {activityGroups.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.label}
                              </option>
                            ))}
                          </select>

                          <select className="search__categories1" name="act" value={filters.act} onChange={handleActChange}>
                            <option value="" disabled>
                            {loader.act ? "Loading..." : "Activities"}
                            </option>
                            {activities &&
                              activities.length > 0 &&
                              activities.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select>

                          <select
                            className="search__categories1"
                            name="subact"
                            value={filters.subact}
                            onChange={handleSubActChange}
                          >
                            <option value="" disabled>
                              Sub Activities
                            </option>
                            {subActivities &&
                              subActivities.length > 0 &&
                              subActivities.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select> 

                          <select className="search__categories1" name="visa" value={filters.visa} onChange={handleVisaChange}>
                            <option value="" disabled>
                              No of Visa
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>

                      </>}

                    {active === 'fl' && <>

                          <select className="search__categories1" name="emi" value={filters.emi} onChange={handleFlEmiChange}>
                            <option value="" disabled>
                            {loader.emi ? "Loading..." : "Emirates"}
                            </option>
                            {emirates &&
                              emirates.length > 0 &&
                              emirates.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select>

                          <select className="search__categories1" name="sub" value={filters.sub} onChange={handleFlSubLicChange}>
                            <option value="" disabled>
                            {loader.sub ? "Loading..." : "Activity Group"}
                            </option>
                            {subLicenses && subLicenses.length > 0 &&
                              subLicenses.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select>

                          <select
                            className="search__categories1"
                            name="subact"
                            value={filters.subact}
                            onChange={handleSubActChange}
                          >
                            <option value="" disabled>
                              Sub Activities
                            </option>
                            {subActivities && subActivities.length > 0 &&
                              subActivities.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.label}
                                </option>
                              ))}
                          </select> 

                          <select className="search__categories1" name="visa" value={filters.visa} onChange={handleVisaChange}>
                            <option value="" disabled>
                              No of Visa
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>                        
                          
                          
                          </>}

                          
                        </form>

                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container white-color">
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                      <form
                        className="hero-form tour-advance"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={filters.search}
                            onChange={onSearchInputChange}
                            placeholder="Type Keyword Here... (eg - E-commerce pharma trading)"
                            aria-label="Search"
                          />
                          <button className="btn btn-md tra-white-hover"></button>
                        </div>
                      </form>
                      <br></br>
                      <button type="button" onClick={handleSearch} className="btn btn-md btn-rose tra-white-hover">
                        Search
                      </button>

                      <div className="services-6-txt">
                        <br />

                        <h4 className="h3-md">OR </h4>
                        <h3 className="h3-md">Customize your Package </h3>

                        <p className="p-md">Now create your own business package and grow your business</p>
                        <span className="tour-custom">
                        <Link to="/site/packages" className="btn btn-md btn-rose tra-white-hover ">
                          Get Started
                        </Link></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomSearch;
