
import {REGISTER_CLOSE,REGISTER_OPEN,TOUR_OPEN,TOUR_CLOSE,TOUR_POPUP_OPEN,TOUR_POPUP_CLOSE } from './registerActionTypes';

export function registerOpen() {
    return { type: REGISTER_OPEN };
}

export function registerClose() {
    return { type: REGISTER_CLOSE };
}

export function tourOpen(){
    return {type:TOUR_OPEN}
}

export function tourClose(){
    return {type:TOUR_CLOSE}
}

export function tourPopupOpen(){
    return {type:TOUR_POPUP_OPEN}
}

export function tourPopupClose(){
    return {type:TOUR_POPUP_CLOSE}
}
