import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Popup2 = ({ emiTc }) => {
  return (
    <section id="categories-2" className="categories-section division">
      <div className="">
        <div className="row">         
          <Popup
            trigger={
              <span className="popup13">               
                Terms and Conditions <span className="popup12">Click Here</span>
              </span>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal model1">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header">Terms and Conditions</div>
                <div className="content" dangerouslySetInnerHTML={{__html:emiTc}} ></div>
              </div>
            )}
          </Popup>          
        </div>        
      </div>      
    </section>
  );
};

export default Popup2;
