import { toast } from 'react-toastify';
import { CART_ADD_ITEM,CART_ADD_ITEM_WITH_ADDON ,
    CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_UPDATE_QUANTITY,
    CART_REMOVE_ADDON_ITEM, CART_EMPTY, CART_TOGGLE} from './cartActionTypes';

export function cartToggle(show=false){
    return {type:CART_TOGGLE, show}
}

export function cartAddItemSuccess(product, options = [], quantity = 1,pack) {
 
    // toast.success(`Product "${product.title}" added to cart!`, { theme: 'colored' });

    return {
        type: CART_ADD_ITEM,        
        product,
        options,
        quantity,pack
    };
}


export function cartAddItemWithAddonSuccess(product, options = [], quantity = 1,pack) {
    toast.success(`Products added to cart!`, { theme: 'colored' });

    return {
        type: CART_ADD_ITEM_WITH_ADDON,
        product,
        options,
        quantity,pack
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartRemoveAddonItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ADDON_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartUpdateQuantitySuccess(item) {
    return {
        type: CART_UPDATE_QUANTITY,
        item,
    };
}

export function cartAddItemWithAddon(product, options = [], quantity = 1, pack='main') {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemWithAddonSuccess(product, options, quantity,pack));
                resolve();
            }, 500);
        })
    );
}


export function cartAddItem(product, options = [], quantity = 1,pack='main') {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product,options, quantity,pack));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveAddonItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveAddonItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantity(item) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitySuccess(item));
                resolve();
            }, 500);
        })
    );
}

export function resetCart(){
    return (dispatch) => {        
                dispatch({ type: CART_EMPTY});
    }
}
