import { ADDON_ADD_ITEM, ADDON_REMOVE_ITEM, ADD_ADDIN_ADDON, REMOVE_ADDIN_ADDON ,ADDON_EMPTY } from './addonActionTypes';

function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x._id === product._id);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }

    return state.filter((x) => x._id !== product._id);
}

function addAdditonal(state, product) {
    const itemIndex = state.findIndex((x) => x._id === product._id);
    console.log(itemIndex);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }
    else{
        const data = state;
        data[itemIndex] = product;
        return data;
    }
   
}



const initialState = [];

export default function addonReducer(state = initialState, action) {
    switch (action.type) {
    case ADDON_ADD_ITEM:
        return addItem(state, action.product);

    case ADDON_REMOVE_ITEM:
        return state.filter((x) => x._id !== action.productId);

    case ADD_ADDIN_ADDON:
            return addAdditonal(state, action.product);
    
    case REMOVE_ADDIN_ADDON:
            return state.filter((x) => x._id !== action.productId);
    case ADDON_EMPTY:
        return initialState;

    default:
        return state;
    }
}
