// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import {  Cross10Svg, Compare16Svg } from '../../svg';
import { cartRemoveItem, cartToggle } from '../../store/cart';
import { compareRemoveItem, compareToggle } from '../../store/compare';
import { url } from '../../services/utils';
// import checkoutApi from '../../api/checkout';

function IndicatorCompare(props) {
    const { cart,compare, compareRemoveItem, compareToggle,  locale } = props;
    let dropdown;
    // let totals;
    // const cartref = useRef();
    // const history = useHistory();  
    
    // console.log(compare)
    

    // const handleCheckout =(e) =>{
    //     e.preventDefault(); 
    //     let address={email:"",mobile:""};               
    //     checkoutApi.addToCart({...cart,currency,address}).then(
    //         result => {
    //             // console.log(result)
    //             // cartref.current.toggle() 
    //             cartToggle(false);               
    //             history.push('/shop/checkout');
    //         },
    //         )
        
    // }

    if (cart.extraLines.length > 0) {
        // const extraLines = cart.extraLines.map((extraLine, index) => (
        //     <tr key={index}>
        //         <th>{extraLine.title}</th>
        //         <td><Currency value={extraLine.price} /></td>
        //     </tr>
        // ));

        // totals = (
        //     <React.Fragment>
        //         <tr>
        //             <th>Subtotal</th>
        //             <td><Currency value={cart.subtotal} /></td>
        //         </tr>
        //         {extraLines}
        //     </React.Fragment>
        // );
    }

    const items = compare.items.map((item) => {
        // let options;
        let image;

        
        if (item.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item)} className="product-image__body">
                        <img className="product-image__img" src={item.images[0].url} alt="" />                       
                    </Link>
                </div>
            );
        }

       

        const removeButton = (
            <AsyncAction
                action={() => compareRemoveItem(item._id)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />                        
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item)}>{item.title}</Link>
                    </div>                    
                    <div className="dropcart__product-meta">
                        {/* <span className="dropcart__product-quantity">{item.quantity}</span> */}
                        {/* {' × '} */}
                        <span className="dropcart__product-price"><Currency value={item.offerPrice*1} /></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (compare.items.length>0) {
        dropdown = (
            <div className="dropcar">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/compare"
                    onClick={()=>compareToggle(false)} >View Compare</Link>
                                        
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcar">
                <div className="dropcart__empty">
                You have not chosen any products to compare!
                </div>
            </div>
        );
    }

    return (<>
         {/* <Indicator ref={cartref} url="/shop/cart" dropdown={dropdown} value={cart.quantity} name1={"cart"} icon={<Cart20Svg />} /> */}
        <Indicator onClick={()=>compareToggle(true)} value={compare.items.length} name1={"compare"} icon={<Compare16Svg />} />

        <Offcanvas show={compare.show} onHide={()=>compareToggle(false)} placement={locale === 'ar' ? 'start' : 'end'}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Compare</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {dropdown}
            </Offcanvas.Body>
      </Offcanvas>
        </>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    compare: state.compare,
    auth: state.auth,
    currency:state.currency,
    locale:state.locale,
});

const mapDispatchToProps = {
    cartRemoveItem, cartToggle,compareToggle,compareRemoveItem
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCompare);
