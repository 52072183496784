// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import RatingsX from "./RatingsX";
import { cartAddItem } from "../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { registerOpen } from "../../store/register";
import { url } from "../../services/utils";
import { wishlistAddItem } from "../../store/wishlist";

function ProductCard(props) {
  const { product, layout, page, badgetype, quickviewOpen, cartAddItem, wishlistAddItem, compareAddItem,
     registerOpen, isLogin, tour=false } =    props;

  const containerClasses = classNames("product-card", {
    "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
    "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
    "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
    "product-card--layout--list": layout === "list",
    "product-card--layout--horizontal": layout === "horizontal",
  });

  let badges = [];
  let image;
  // let price;
  let features;

  if (badgetype === 3) {
    badges.push(
      <div key="flexi" className="product-card__badge product-card__badge--sale">
        <img className="product-image__img1" src="/images/side-bar-FINAL-04.png" alt="" />
      </div>
    );
  }
  if (badgetype === 2) {
    badges.push(
      <div key="flexiplus" className="product-card__badge product-card__badge--hot">
        <img className="product-image__img1" src="/images/side-bar-FINAL-03.png" alt="" />
      </div>
    );
  }
  if (badgetype === 0) {
    badges.push(
      <div key="saverplus" className="product-card__badge product-card__badge--hot">
        <img className="product-image__img1" src="/images/side-bar-FINAL-02.png" alt="" />
      </div>
    );
  }
  if (badgetype === 1) {
    badges.push(
      <div key="new" className="product-card__badge product-card__badge--new">
        <img className="product-image__img1" src="/images/saver1.png" alt="" />
      </div>
    );
  }
  // if (product.badges.includes('new')) {
  //     badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
  // }

  badges = page === 1 && badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

  if (product.images && product.images.length > 0) {
    image = (
      <div className="product-card__image product-image">
        <Link to={url.product(product)} className="product-image__body">
          <img className="product-image__img" src={product.images[0].url} alt="" />
        </Link>
      </div>
    );
  } else {
    image = (
      <div className="product-card__image product-image">
        <Link to={url.product(product)} className="product-image__body">
          <img className="product-image__img" src="/images/image-01.jpg" alt="" />
        </Link>
      </div>
    );
  }

  // if (product.compareAtPrice) {
  //     price = (
  //         <div className="product-card__prices">
  //             <span className="product-card__new-price"><Currency value={product.price} /></span>
  //             {' '}

  //             <span className="product-card__old-price"><Currency value={product.compareAtPrice} /></span>
  //         </div>
  //     );
  // } else {
  //     price = (
  //         <div className="product-card__prices">
  //             <Currency value={500} />
  //         </div>
  //     );
  // }

  // if (product.attributes && product.attributes.length) {
  //     features = (
  //         <ul className="product-card__features-list">
  //          <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
  //             {product.attributes.filter((x) => x.featured).map((attribute, index) => (
  //                 <li key={index}>{` ${attribute.values.map((x) => x.name).join(', ')}`}</li>

  //             ))}
  //         </ul>
  //     );
  // }

  if (product.highlights && product.highlights.length) {
    features = (
      <ul className="product-card__features-list">
        {product.highlights.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    );
  }

  return (
    <div className={containerClasses}>
      <AsyncAction
        action={() => quickviewOpen(product._id)}
        render={({ run, loading }) => (
          <button
            type="button"
            onClick={run}
            className={classNames("product-card__quickview tooltip1", {
              "product-card__quickview--preload": loading,
            })}
          >
            <Quickview16Svg />
            <span className="tooltiptext">Quick View</span>
          </button>
        )}
      />
      {badges}
      {image}
      <div className="product-card__info">
        <div className="product-card__name">
          <Link to={url.product(product)}>{product.title}</Link>
        </div>
        <div className="buttons">
          <button>Sold {product.sold}</button>
          <button>views {product.views}</button>
        </div>
        <div className="product-card__rating">
          <RatingsX ratings={product.ratings} />
          <div className=" product-card__rating-legend">{product.ratings.length} Reviews</div>
        </div>
        {features}
      </div>
      <div className="product-card__actions">
        <div className="product-card__availability">
          Availability:
          {product.availability ? (
            <span className="text-success pl-2">In Stock</span>
          ) : (
            <span className="text-danger pl-2">Out of Stock</span>
          )}
        </div>
        {isLogin ? (
          <div>
            <div className="money_bag">
              <p className="delproduct">
                <del>
                  <Currency value={product.salePrice} />
                </del>
                <span className="savemony pl-2">
                  save <Currency value={product.salePrice - product.offerPrice} />
                </span>
              </p>
            </div>
            <Currency value={product.offerPrice} />
          </div>
        ) : (
          <button type="button" className={`btn btn-primary btn-sm mt-2 ${tour?"tour-view":""}`} onClick={() => registerOpen()}>
            View Price
          </button>
        )}

        <div className="product-card__buttons">
          {isLogin ? (
            <AsyncAction
              action={() => cartAddItem(product)}
              render={({ run, loading }) => (
                <React.Fragment>
                  <button
                    type="button"
                    onClick={run}
                    className={classNames("btn btn-primary product-card__addtocart", {
                      "btn-loading": loading,
                    })}
                  >
                    Add To Cart
                  </button>
                  <button
                    type="button"
                    onClick={run}
                    className={classNames("btn btn-secondary product-card__addtocart product-card__addtocart--list", {
                      "btn-loading": loading,"tour-cart":tour,
                    })}
                  >
                    Add To Cart
                  </button>
                </React.Fragment>
              )}
            />
          ) : (
            <>
              <button type="button" onClick={() => registerOpen()} className="btn btn-primary product-card__addtocart">
                Add To Cart
              </button>
              <button
                type="button"
                onClick={() => registerOpen()}
                className={classNames("btn btn-secondary product-card__addtocart product-card__addtocart--list", {
                  "tour-cart":tour,
                })}
              >
                Add To Cart
              </button>
            </>
          )}
          {isLogin ? (
            <AsyncAction
              action={() => wishlistAddItem(product)}
              render={({ run, loading }) => (
                <button
                  type="button"
                  onClick={run}
                  className={classNames("btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist tooltip1", {
                    "btn-loading": loading,"tour-wishlist":tour,
                  })}
                >
                  <Wishlist16Svg />
                  <span className="tooltiptext">Add to whishlist</span>
                </button>
              )}
            />
          ) : (
            <button
              type="button"
              onClick={() => registerOpen()}
              className={classNames("btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist tooltip1", {
                "tour-wishlist":tour,
              })}
            >
              <Wishlist16Svg />
              <span className="tooltiptext">Add to whishlist</span>
            </button>
          )}
          {isLogin ? (
            <AsyncAction
              action={() => compareAddItem(product)}
              render={({ run, loading }) => (
                <button
                  type="button"
                  onClick={run}
                  alt="test"
                  title="test"
                  className={classNames("btn btn-light btn-svg-icon  btn-svg-icon--fake-svg product-card__compare tooltip1 ", {
                    "btn-loading": loading,"tour-compare":tour,
                  })}
                >
                  <Compare16Svg />
                  <span className="tooltiptext">Add to compare Packages</span>
                </button>
              )}
            />
          ) : (
            <button
              type="button"
              onClick={() => registerOpen()}
              alt="test"
              title="test"
              className={classNames("btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare tooltip1",{
                "tour-compare":tour, 
              })}
            >
              <Compare16Svg />
              <span className="tooltiptext">Add to compare Packages</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  /**
   * product object
   */
  product: PropTypes.object.isRequired,
  /**
   * product card layout
   * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
   */
  layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
  badgetype: PropTypes.number,
  page: PropTypes.number,
};

ProductCard.defaultProps = {
  badgetype: 5,
  page: 0,
};

const mapStateToProps = (state) => ({
  isLogin: state.auth.isLogin,
});

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  quickviewOpen,
  registerOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
