import { makeIdGenerator } from '../utils';

const getId = makeIdGenerator();

const categoriesDef = [
    {
        name: 'mainland',
        slug: 'mainland',
        items: 272,
        children: [
            {
                name: 'Mainland1',
                slug: 'power-tools',
                image: 'assets/images/categories/category-1.jpg',
                items: 370,
                children: [
                    {
                        name: 'Mainland2',
                        slug: 'drills-mixers',
                        items: 57,
                    },
                    {
                        name: 'Mainland3',
                        slug: 'cordless-screwdrivers',
                        items: 15,
                    },
                    {
                        name: 'Mainland4',
                        slug: 'screwdrivers',
                        items: 126,
                    },
                    {
                        name: 'Mainland5',
                        slug: 'wrenches',
                        items: 12,
                    },
                    
                ],
            },
            
        ],
    },
    {
        name: 'Freelance',
        slug: 'electronics',
        items: 54,
    },
    {
        name: 'Freelance1',
        slug: 'computers',
        items: 421,
    },
    {
        name: 'Freelance1',
        slug: 'automotive',
        items: 182,
    },
    {
        name: 'Freelance1',
        slug: 'furniture-appliances',
        items: 15,
    },
    {
        name: 'Freelance1',
        slug: 'music-books',
        items: 89,
    },
    {
        name: 'Freelance1',
        slug: 'health-beauty',
        items: 201,
    },
];

function walkTree(defs, parent = null) {
    let list = [];
    const tree = defs.map((def) => {
        const category = {
            id: getId(),
            name: def.name,
            slug: def.slug,
            image: def.image || null,
            items: def.items || 0,
            customFields: {},
            parent,
            children: [],
        };

        const [childrenTree, childrenList] = walkTree(def.children || [], category);

        category.children = childrenTree;
        list = [...list, category, ...childrenList];

        return category;
    });

    return [tree, list];
}

export function prepareCategory(category, depth) {
    let children;

    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }

    return JSON.parse(JSON.stringify({
        ...category,
        parent: category.parent ? prepareCategory(category.parent) : null,
        children,
    }));
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);
