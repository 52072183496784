// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import Popup from 'reactjs-popup';
import "reactjs-popup/dist/index.css";
import Popup1 from '../Demo1/popup1';

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";

import RatingsX from "./RatingsX";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import {registerOpen} from '../../store/register'
import { Wishlist16Svg, Compare16Svg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";
// import CoursesListCourses3 from "../Demo1/CoursesListCourses3";
// import Blockcat1 from '../blocks/Blockcat1';
// import BlockPosts from '../blocks/BlockPosts';
// import posts from '../../data/blogPosts';
// import Eminew from "../Demo1/Eminew";
import Contract from "../Demo1/Contract";
import Popup2 from "../Demo1/popup2";
import Popup3 from "../Demo1/popup3";
// import { NavItem } from 'reactstrap';

import {FacebookIcon,FacebookShareButton,WhatsappIcon, WhatsappShareButton,XIcon,
  TwitterShareButton, } from 'react-share';

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
      more1:false,
      more2:false,
    };
  }

  handleChangeQuantity = (quantity) => {
    this.setState({ quantity });
  };

  handleMoreText1 = () =>{
    this.setState({more1:!this.state.more1})
  }
  handleMoreText2 = () =>{
    this.setState({more2:!this.state.more2})
  }

  render() {
    const { product, layout, wishlistAddItem, compareAddItem, cartAddItem, isLogin, registerOpen,
       additional,  } = this.props;
    const { quantity,more2 } = this.state;

    // const description = more1 ? product.description : `${product.description?.substr(0,150)}....`;
    const adescription = more2 ? product.activityDescription : `${product.activityDescription?.substr(0,150)}....`;
    const shareUrl = window.location.href;
    const title = product.title;

    return (
      // <div className={`product product--layout--${layout}`}>

      <div className="product product--layout--columnar">
        <div className="product__content">
          <ProductGallery layout={layout} product={product} images={product.images} additional={additional}  />

          <div className="product__info">
            <div className="product__wishlist-compare">
              { isLogin ?
              <AsyncAction
                action={() => wishlistAddItem(product)}
                render={({ run, loading }) => (
                  <button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Wishlist"
                    onClick={run}
                    className={classNames("btn btn-sm btn-light btn-svg-icon tooltip1", {
                      "btn-loading": loading,
                    })}                   
                  >
                    <Wishlist16Svg />
                    <span className="tooltiptext">Add to whishlist</span>
                  </button>
                )}
              /> :<button
              type="button"
              data-toggle="tooltip"
              data-placement="right"
              title="Wishlist"
              onClick={()=>registerOpen()}
              className="btn btn-sm btn-light btn-svg-icon tooltip1"
            >
              <Wishlist16Svg />
              <span className="tooltiptext">Add to whishlist</span>
            </button> }

            {isLogin ? 
              <AsyncAction
                action={() => compareAddItem(product)}
                render={({ run, loading }) => (
                  <button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Compare"
                    onClick={run}
                    className={classNames("btn btn-sm btn-light btn-svg-icon tooltip1", {
                      "btn-loading": loading,
                    })}                   
                  >
                    <Compare16Svg />
                    <span className="tooltiptext">Add to compare Packages</span>
                  </button>
                )}
              />: <button
              type="button"
              data-toggle="tooltip"
              data-placement="right"
              title="Compare"
              onClick={()=>registerOpen()}
              className="btn btn-sm btn-light btn-svg-icon tooltip1">
              <Compare16Svg />
              <span className="tooltiptext">Add to compare Packages</span>
            </button>}

            </div>
            <h1 className="product__name">{product.title}</h1>
            <div className="product__rating">
              <div className="product__rating-stars">
                <RatingsX ratings={product.ratings} />
              </div>
              <div className="product__rating-legend">
                <Link to="/">{`${product.ratings.length} Reviews`}</Link>
                {/* <span>/</span>
                                <Link to="/">Write A Review</Link> */}
              </div>
            </div>
            <div className="product__description">            
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
              {/* <span role='button' className='text-danger' onClick={this.handleMoreText1}>{more1?'Show less':'Read more'}</span> */}
              <h6>Activity Description</h6>
              <div dangerouslySetInnerHTML={{ __html: adescription }} />
              <span role='button' className='text-danger' onClick={this.handleMoreText2}>{more2?'Show less':'Read more'}</span>
            </div>
            <ul className="product__meta">
              <li className="product__meta-availability">
                Availability: <span className="text-success">{product.availability ? "Available" : "Not Available"}</span>
              </li>
              <li>
                category:
                <Link to="#">{product.category && product.category.label}</Link>
              </li>
              <li>
              Location:
                <Link to="#">{product.emirate && product.emirate.label}</Link>
              </li>
              <li>
                Zone:
                <Link to="#">{product.zone && product.zone.label}</Link>
              </li>
              <li>
                {" "}
                SKU:<Link to="#"> {product.sku}</Link>
              </li>
            </ul>
            <br></br>
            <Contract validity={product.licenseValidity} product={product.contractOption} offerPrice={product.offerPrice} isLogin={isLogin} />
            {/* <Eminew product={product} /> */}
            <Popup1 /><br></br>
            <Popup3 bonusPoint={product.bonusPoint} /> {/*  bonus */}
            <Popup2 emiTc={product.emiTc} /> {/*  terms */}
          </div>

          <div className="product__sidebar">
            <div className="product__availability">
              Availability:{product.availability ? <span className="text-success pl-2">In Stock</span> : <span className="text-danger pl-2">Out of Stock</span>}
            </div>
            {isLogin ?
            <div>
            <div className="money_bag">
              <p className="delproduct">
                <del>
                  <Currency value={product.salePrice*1} />
                </del>
                <span className="savemony pl-2">
                  save <Currency value={product.salePrice - product.offerPrice} />
                </span>
              </p>
            </div>
            <div className="product__prices">
              <Currency value={product.offerPrice} />
            </div>
            </div>:<button type='button' className='btn btn-primary btn-sm mt-2' onClick={()=>registerOpen()}>View Price</button> }

            <form className="product__options">
              {/* <div className="form-group product__option">
                                <div className="product__option-label">Color</div>
                                <div className="input-radio-color">
                                    <div className="input-radio-color__list">
                                        <label
                                            className="input-radio-color__item input-radio-color__item--white"
                                            style={{ color: '#fff' }}
                                            data-toggle="tooltip"
                                            title="White"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item"
                                            style={{ color: '#ffd333' }}
                                            data-toggle="tooltip"
                                            title="Yellow"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item"
                                            style={{ color: '#ff4040' }}
                                            data-toggle="tooltip"
                                            title="Red"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item input-radio-color__item--disabled"
                                            style={{ color: '#4080ff' }}
                                            data-toggle="tooltip"
                                            title="Blue"
                                        >
                                            <input type="radio" name="color" disabled />
                                            <span />
                                        </label>
                                    </div>
                                </div>
                            </div> */}
              {/* <ul className="product__meta">
                            <li className="text-success">
                            Save Now
                                {' '}
                                <span className="text-success">AED 400</span>
                            </li>
                           
                        </ul> */}
              {/* <div className="form-group product__option">
                                <div className="product__option-label">Material</div>
                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                        <label>
                                            <input type="radio" name="material" />
                                            <span>Metal</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="material" />
                                            <span>Wood</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="material" disabled />
                                            <span>Plastic</span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
              <div className="form-group product__option">
                <label htmlFor="product-quantity" className="product__option-label">
                  Quantity
                </label>
                <div className="product__actions">
                  <div className="product__actions-item">
                    <InputNumber id="product-quantity" aria-label="Quantity" className="product__quantity" size="lg" min={1} value={quantity} onChange={this.handleChangeQuantity} />
                  </div>
                  <div className="product__actions-item product__actions-item--addtocart">
                    {isLogin?
                    <AsyncAction
                      action={() => cartAddItem(product, [], quantity)}
                      render={({ run, loading }) => (
                        <button
                          type="button"
                          onClick={run}                         
                          className={classNames("btn btn-primary btn-lg", {
                            "btn-loading": loading,
                          })}                         
                        >
                          Add to cart
                        </button>
                      )}
                    />:<button
                    type="button"
                    onClick={()=>registerOpen()}                         
                    className="btn btn-primary btn-lg"
                  >
                    Add to cart
                  </button>
                    }
                  </div>
                  <div className="product__actions-item product__actions-item--wishlist">
                    {isLogin?
                    <AsyncAction
                      action={() => wishlistAddItem(product)}
                      render={({ run, loading }) => (
                        <button
                          type="button"
                          data-toggle="tooltip"
                          title="Wishlist"
                          onClick={run}
                          className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                            "btn-loading": loading,
                          })}                          
                        >
                          <Wishlist16Svg />
                        </button>
                      )}
                    />: <button
                    type="button"
                    data-toggle="tooltip"
                    title="Wishlist"
                    onClick={()=>registerOpen()}
                    className="btn btn-secondary btn-svg-icon btn-lg"                                    
                  >
                    <Wishlist16Svg />
                  </button>}
                  </div>

                  <div className="product__actions-item product__actions-item--compare">
                    {isLogin?
                    <AsyncAction
                      action={() => compareAddItem(product)}
                      render={({ run, loading }) => (
                        <button
                          type="button"
                          data-toggle="tooltip"
                          title="Compare"
                          onClick={run}
                          className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                            "btn-loading": loading,
                          })}                         
                        >
                          <Compare16Svg />
                        </button>
                      )}
                    />: <button
                    type="button"
                    data-toggle="tooltip"
                    title="Compare"
                    onClick={()=>registerOpen()}
                    className="btn btn-secondary btn-svg-icon btn-lg"                         
                  >
                    <Compare16Svg />
                  </button>}
                  </div>
                </div>
              </div>
            </form>
            <br></br>
            <div>
              <h4>Location Map</h4>
            </div>

            <div className="block-map block">
              <div className="block-map__body">
                {product.zone?.location &&
                  <iframe src={product.zone?.location} width="250" height="250" title="google-map"
                    style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">                  
                  </iframe>
                }
              </div>
            </div>
            <div>
          
          </div>
          {product.zone?.ads &&
            <img className="" src={product.zone.ads?.url} alt="category-icon" />}
          </div>
          

          <div className="product__footer">
            <div className="product__tags tags">
              <h6>Tags</h6>
              <div className="tags__list">
                {product.tags.length > 0 &&
                  product.tags.map((tag, idx) => (
                    <Link to="#" key={idx}>
                      {tag}
                    </Link>
                  ))}
              </div>
            </div>

            <div className="product__share-links share-links">
              <h6>Share</h6>
              <FacebookShareButton
          url={shareUrl}
          className="m-1"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>        

        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="m-1"
        >
          <XIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="m-1"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        {/* <PinterestShareButton
          url={shareUrl}          
          className="m-1"
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton> */}

        {/* <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="m-1"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton> */}

              {/* <ul className="share-links__list">
                <li className="share-links__item share-links__item--type--like">
                  <Link to="#">Like</Link>
                </li>
                <li className="share-links__item share-links__item--type--tweet">
                  <Link to="#">Tweet</Link>
                </li>
                <li className="share-links__item share-links__item--type--pin">
                  <Link to="#">Pin It</Link>
                </li>
                <li className="share-links__item share-links__item--type--counter">
                  <Link to="#">4K</Link>
                </li>
              </ul> */}
            </div>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  /** product object */
  product: PropTypes.object.isRequired,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
  layout: "standard",
};

const mapStateToProps = (state)=>({
  isLogin:state.auth.isLogin,
})

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  registerOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
