import React from 'react';
import Joyride from 'react-joyride';
// import { Modal } from 'react-bootstrap';

const steps = [
  {
    content: <h2>Let's begin our journey!</h2>,    
    placement: 'center',
    target: 'body',
  },
  {
    target: '.tour-search',
    content: 'Search this package',
  },
  {
    target: '.tour-plus',
    content: 'customize your packages.',
  },
  {
    target: '.tour-login',
    content: 'login or register your account',
  },
  {
    target: '.tour-bestsell',
    content: 'Best Selling packages',
  },
  
  {
    target: '.tour-advance',
    content: 'advanced search here.',
  },
  {
    target: '.tour-custom',
    content: 'customize your packages.',
  },
];

export default function JoyrideProvider() {
  // const [open,setOpen] = useState(false);
  // const [run, setRun] = useState(false);
  return (
    <>
      <Joyride
        continuous
        run={window.location.pathname==='/'}
        // scrollToFirstStep
        // showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {/* <Modal show={open}  onHide={()=>setOpen(!open)}  centered>
          <Modal.Header closeButton>  Website tour </Modal.Header>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center' style={{minHeight:"150px"}}>
            <button className='btn btn-primary' onClick={()=>{
              setRun(true); setOpen(false)
              }} >Take a Tour</button>
            </div>
          </Modal.Body>
      </Modal> */}
    </>
  )
}
