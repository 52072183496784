// react
import React,{lazy, Suspense,} from "react";
import "./stripe.css";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";
import BlockLoader from "./blocks/BlockLoader";

// data stubs
import theme from "../data/theme";
import { useDeferredData } from "../services/hooks";
import headerApi from "../api/header";

// pages
const AccountLayout = lazy(()=>import("./account/AccountLayout"));
const OrderPageLayout = lazy(()=>import("./account/OrderPageLayout"));
const AccountPageLogin = lazy(()=>import("./account/AccountPageLogin"));
const AccountPageVendorRegistor = lazy(()=>import("./account/AccountPageVendorRegister"));
const AccountPageAgentRegistor = lazy(()=>import("./account/AccountPageAgentRegister"));
const AccountThankyouInformation = lazy(()=>import("./account/AccountThankyouInformation"));
const BlogPageCategory = lazy(()=>import("./blog/BlogPageCategory"));
const BlogPagePost = lazy(()=>import("./blog/BlogPagePost"));
const PageCart = lazy(()=>import("./shop/ShopPageCart"));
const PageCheckout = lazy(()=>import("./shop/ShopPageCheckout"));
const PageCompare = lazy(()=>import("./shop/ShopPageCompare"));
const PageWishlist = lazy(()=>import("./shop/ShopPageWishlist"));
const ShopPageCategory = lazy(()=>import("./shop/ShopPageCategory"));
const ShopPageOrderSuccess = lazy(()=>import("./shop/ShopPageOrderSuccess"));
const ShopPageProduct = lazy(()=>import("./shop/ShopPageProduct"));
const ShopPageTrackOrder = lazy(()=>import("./shop/ShopPageTrackOrder"));
const ShopPagePayment = lazy(()=>import("./shop/ShopPagePayment"));

const SitePageAboutUs = lazy(()=>import("./site/SitePageAboutUs"));
const SitePageBank = lazy(()=>import("./site/SitePageBank"));
const SitePagePackagetype = lazy(()=>import("./site/SitePagePackagetype"));
const SitePageMainlandSearchForm = lazy(()=>import("./site/SitePageMainlandSearchForm"));
const SitePageFreezoneSearchForm = lazy(()=>import("./site/SitePageFreezoneSearchForm"));
const SitePageFreeLanceSearchForm = lazy(()=>import("./site/SitePageFreeLanceSearchForm"));
const SitePageCustomSearchForm = lazy(()=>import("./site/SitePageCustomSearchForm"));

const SitePageFreezone = lazy(()=>import("./site/SitePageFreezone"));
const SitePageProservice = lazy(()=>import("./site/SitePageProservice"));
const SitePageBanksingle = lazy(()=>import("./site/SitePageBanksingle"));
const SitePageComponents = lazy(()=>import("./site/SitePageComponents"));
const SitePageContactUs = lazy(()=>import("./site/SitePageContactUs"));
const SitePageContactUsAlt = lazy(()=>import("./site/SitePageContactUsAlt"));
const SitePageFaq = lazy(()=>import("./site/SitePageFaq"));
const SitePageNotFound = lazy(()=>import("./site/SitePageNotFound"));
const SitePageTerms = lazy(()=>import("./site/SitePageTerms"));
const SitePageTypography = lazy(()=>import("./site/SitePageTypography"));
const SitePagePrivacy = lazy(()=>import("./site/SitePagePrivacy"));

const SitePageVendor = lazy(()=>import("./site/SitePageVendor"));
const SitePageEvent = lazy(()=>import("./site/SitePageEvent"));
const SitePageEventDetails = lazy(()=>import("./site/SitePageEventDetails"));
const SitePageagent = lazy(()=>import("./site/SitePageagent"));
const SitePageAds = lazy(()=>import("./site/SitePageAds"));
const SitePageSingleFreezone = lazy(()=>import("./site/SitePageSingleFreezone"));
const SitePageTestimonial = lazy(()=>import("./site/SitePageTestimonial"));
const SitePageFreezoneCustomSearch = lazy(()=>import("./site/SitePageFreezoneCustomSearch"));
const SitePageFreezoneCustomSearch2 = lazy(()=>import("./site/SitePageFreezoneCustomSearch2"));
const SitePageFreelanceCustomSearch2 = lazy(()=>import("./site/SitePageFreelanceCustomSearch2"));
const SitePageOrderDetails = lazy(()=>import("./account/SitePageOrderDetails"));
const EquotePdf = lazy(()=>import("./shop/EquotePdf"));

const ResetPassword = lazy(()=>import("./site/ResetPassword"));
const UpdatePassword = lazy(()=>import("./site/UpdatePassword"));


const categoryLayouts = [
  ["/shop/columns-full1", { columns: 4, viewMode: "grid" }],
  ["/shop/columns-full", { columns: 4, viewMode: "grid" }],
  ["/shop/category-grid-5-columns-full", { columns: 5, viewMode: "grid" }],
  ["/shop/category-list", { columns: 4, viewMode: "grid" }],
  ["/shop/category-right-sidebar", { columns: 4, viewMode: "grid" }],
].map(([url, options]) => (
  <Route
    key={url}
    exact
    path={url}
    render={(props) => (
      <ShopPageCategory {...props} {...options} categorySlug="power-tools" />
    )}
  />
));

const productLayouts = [
  ["/shop/product-standard", { layout: "standard" }],
  ["/shop/product-columnar", { layout: "columnar" }],
  ["/shop/product-sidebar", { layout: "sidebar" }],
].map(([url, options]) => (
  <Route
    key={url}
    exact
    path={url}
    render={(props) => (
      <ShopPageProduct
        {...props}
        {...options}
        productSlug="brandix-screwdriver-screw1500acc"
      />
    )}
  />
));

function Layout(props) {
  const { match, headerLayout, homeComponent } = props;
  const departments = useDeferredData(() => headerApi.getDepartments(), []);
  const navLink = useDeferredData(() => headerApi.getHeaderNavs(), []);

  return (
    <React.Fragment>

      <Helmet>
        <title>{theme.name}</title>
        <meta name="description" content={theme.fullName} />
      </Helmet>

      <ToastContainer autoClose={4000} hideProgressBar />

      <Quickview />

      <MobileMenu />

      <div className="site">

        <header className="site__header d-lg-none">
          <MobileHeader />
        </header>

        <header className="site__header d-lg-block d-none">
          <Header layout={headerLayout} dept={departments.data} hdata={navLink.data} />
        </header>

        <div className="site__body">
        <Suspense fallback={<BlockLoader/>}>
          <Switch>           
            <Route exact path={`${match.path}`} component={homeComponent} />
            <Redirect exact from="/shop" to="/package/catalog" />
            <Route
              exact
              path="/package/catalog"
              render={(props) => <ShopPageCategory {...props} columns={4} viewMode="grid" />}
            />
            <Route
              exact
              path="/package/catalog/:categorySlug"
              render={(props) => (
                <ShopPageCategory {...props} columns={4} viewMode="grid" categorySlug={props.match.params.categorySlug} />
              )}
            />
           
            {categoryLayouts}
           
            <Route
              exact
              path="/shop/products/:productSlug"
              render={(props) => <ShopPageProduct {...props} layout="standard" productSlug={props.match.params.productSlug} />}
            />
           
            {productLayouts}

            <Route exact path="/shop/cart" component={PageCart} />
            <Route exact path="/shop/checkout" component={PageCheckout} />
            <Route exact path="/shop/payment/:type/:orderId" component={ShopPagePayment} />
            <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} />
            <Route exact path="/shop/wishlist" component={PageWishlist} />
            <Route exact path="/shop/compare" component={PageCompare} />
            <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />

           
            <Redirect exact from="/blog" to="/blog/category-classic" />
            <Route
              exact
              path="/blog/category-classic"
              render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />}
            />
            <Route
              exact
              path="/blog/category-grid"
              render={(props) => <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />}
            />
            <Route
              exact
              path="/blog/category-list"
              render={(props) => <BlogPageCategory {...props} layout="list" sidebarPosition="end" />}
            />
            <Route
              exact
              path="/blog/category-left-sidebar"
              render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />}
            />
            <Route
              exact
              path="/blog/post-classic"
              render={(props) => <BlogPagePost {...props} layout="classic" sidebarPosition="end" />}
            />
            <Route exact path="/blog/post-full" render={(props) => <BlogPagePost {...props} layout="full" />} />

           

            {/* <Route exact path="/account/login" component={AccountPageLogin} /> */}
            <Route exact path="/account/registor" component={AccountPageLogin} />
            <Route exact path="/account/vendor-register" component={AccountPageVendorRegistor} />
            <Route exact path="/account/agent-register" component={AccountPageAgentRegistor} />
            <Route exact path="/account/register-success" component={AccountThankyouInformation} />
            <Route path="/account" component={AccountLayout} />
            <Route path="/view" component={OrderPageLayout} />
            <Route path="/e-pdf" component={EquotePdf} />

           
            <Redirect exact from="/site" to="/site/about-us" />
            <Route exact path="/site/about-us" component={SitePageAboutUs} />
            <Route exact path="/site/banks" component={SitePageBank} />
            <Route exact path="/site/proservice" component={SitePageProservice} />
            <Route exact path="/site/bank/:id" component={SitePageBanksingle} />
            <Route exact path="/site/agent" component={SitePageagent} />
            <Route exact path="/site/ads" component={SitePageAds} />
            <Route exact path="/site/singlefreezone/:id" component={SitePageSingleFreezone} />
            <Route exact path="/site/freezone" component={SitePageFreezone} />
            <Route exact path="/site/testimonial" component={SitePageTestimonial} />

            <Route exact path="/site/mainlandsearchform" component={SitePageMainlandSearchForm} />
            <Route exact path="/site/freezonesearchform" component={SitePageFreezoneSearchForm} />
            <Route exact path="/site/freezonesearchform2" component={SitePageFreezoneCustomSearch} />
            <Route exact path="/site/freezonesearchform3" component={SitePageFreezoneCustomSearch2} />
            <Route exact path="/site/freelancesearchform" component={SitePageFreeLanceSearchForm} />
            <Route exact path="/site/freelancesearchform2" component={SitePageFreelanceCustomSearch2} />
            <Route exact path="/site/custom-search-form/:id" component={SitePageCustomSearchForm} />

            <Route exact path="/site/packages" component={SitePagePackagetype} />
            <Route exact path="/site/vendor" component={SitePageVendor} />
            <Route exact path="/site/event" component={SitePageEvent} />
            <Route exact path="/site/eventpage/:id" component={SitePageEventDetails} />

            <Route exact path="/site/components" component={SitePageComponents} />
            <Route exact path="/site/contact-us" component={SitePageContactUs} />
            <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
            <Route exact path="/site/not-found" component={SitePageNotFound} />
            <Route exact path="/site/faq" component={SitePageFaq} />
            <Route exact path="/site/terms" component={SitePageTerms} />
            <Route exact path="/site/privacy" component={SitePagePrivacy} />
            <Route exact path="/site/typography" component={SitePageTypography} />
            <Route exact path="/site/orders/:id" component={SitePageOrderDetails} /> 
            <Route exact path="/site/forgot" component={ResetPassword} />
            <Route exact path="/site/reset" component={UpdatePassword} />          
            <Route component={SitePageNotFound} />
          </Switch>
        </Suspense>
        </div>

        <footer className="site__footer">
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  /**
   * header layout (default: 'classic')
   * one of ['classic', 'compact']
   */
  headerLayout: PropTypes.oneOf(["default", "compact"]),
  /**
   * home component
   */
  homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
  headerLayout: "default",
};

export default Layout;
