// react
import React, {useCallback, useEffect, useRef, useState} from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// third-party
import classNames from 'classnames';
import { withRouter, useHistory } from 'react-router-dom';

// application
import shopApi from '../../api/shop';
import Suggestions from './Suggestions';
import { Cross20Svg, Search20Svg,BlackMic20Svg,RedMic20Svg } from '../../svg';

// function useCategories() {
//     const [categories, setCategories] = useState([]);

//     useEffect(() => {
//         let canceled = false;

//         const treeToList = (categories, depth = 0) => (
//             categories.reduce(
//                 (result, category) => [
//                     ...result,
//                     { depth, ...category },
//                     ...treeToList(category.children || [], depth + 1),
//                 ],
//                 [],
//             )
//         );

       
//         shopApi.getCategories({ depth: 1 }).then((categories) => {
//             if (canceled) {
//                 return;
//             }

//             setCategories(treeToList(categories));
//         });

//         return () => {
//             canceled = true;
//         };
//     }, [setCategories]);

//     return categories;
// }

function Search(props) {
    const {
        context,
        className,
        inputRef,
        onClose,
        location,       
        categories=[]
    } = props;
    const [cancelFn,setCancelFn] = useState(() => () => {});
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions,setHasSuggestions] = useState(false);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [query, setQuery] = useState('');
    const [category, setCategory] = useState('all');
    const history = useHistory();
    // const categories = useCategories();
    const wrapper = useRef(null);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
      } = useSpeechRecognition();


    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }
        setSuggestionsOpen(false);
    }, [onClose]);

    // Close suggestions when the location has been changed.
    useEffect(() => close(), [close, location]);

    // Close suggestions when a click has been made outside component.
    useEffect(() => {
        const onGlobalClick = (event) => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', onGlobalClick);

        return () => document.removeEventListener('mousedown', onGlobalClick);
    }, [close]);

    // Cancel previous typing.
    useEffect(() => () => cancelFn(), [cancelFn]);

    // voice search
    useEffect(()=>{
        if(listening)
        handleChangeQuery({target:{value:transcript}})    // eslint-disable-next-line
    },[transcript])

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
        handleQueryFetch(event.target.value,query)
        console.log(event.target.value);
    };

    const handleChangeQuery = (event) => {
        setQuery(event.target.value);
        handleQueryFetch(category,event.target.value)
        
    };

    const handleQueryFetch = (category,query) => {
        let canceled = false;
        let timer;

        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        }; 

        if (query === '') {
            setHasSuggestions(false);
        } else {
            timer = setTimeout(() => {
                const options = { search:query };

                if (category !== 'all') {
                    options.category = category;
                }

                shopApi.getSuggestions(options).then((products) => {
                    if (canceled) {
                        return;
                    }
                    // console.log('search->',products);
                    setSuggestedProducts(products);
                    setHasSuggestions(true);
                    setSuggestionsOpen(true);
                });
            }, 600);
        }

        setCancelFn(() => newCancelFn);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    // Close suggestions when the Escape key has been pressed.
    const handleKeyDown = (event) => {
        // Escape.
        if (event.which === 27) {
            close();
        }
    };

    const handleVoiceSearch =() =>{
        if(listening){
            SpeechRecognition.stopListening();            
        }
        else{
            resetTranscript();            
            SpeechRecognition.startListening();
        }
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
       
        if(location.pathname === '/package/catalog/'){
            let url = category==='all' ?`/package/catalog/?type=all&search=${query}` :`/package/catalog/?type=cat&cat=${category}&search=${query}`;           
            history.replace(url)
        }
        else{
            let url = category==='all' ?`/package/catalog/?type=all&search=${query}` :`/package/catalog/?type=cat&cat=${category}&search=${query}`;
            history.push(url)
        }
    }

    const rootClasses = classNames(`search search--location--${context}`, className, {
        'search--suggestions-open': suggestionsOpen,
        'search--has-suggestions': hasSuggestions,
    });

    const closeButton = context !== 'mobile-header' ? '' : (
        <button className="search__button search__button--type--close" type="button" onClick={close}>
            <Cross20Svg />
        </button>
    );

    const categoryOptions = categories.map((category) => (
        <option key={category._id} value={category._id}>
            {/* {'\u00A0'.repeat(4 * category.depth)} */}
            {category.title}
        </option>
    ));

    if (!browserSupportsSpeechRecognition) {
        return alert("Browser doesn't support speech recognition.");
      }

    return (
        <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
            <div className="search__body">
                <form className="search__form" onSubmit={handleSubmit}>
                    {context === 'header' && (
                        <select
                            className="search__categories"
                            aria-label="Category"
                            value={category}
                            onFocus={close}
                            onChange={handleChangeCategory}
                        >
                            <option value="all">All</option>
                            {/* <option value="[all1]"> Categories</option> */}
                            {categoryOptions}
                        </select>                        
                    )}
                    <input
                        ref={inputRef}
                        onChange={handleChangeQuery}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        value={query}
                        className="search__input"
                        name="search"
                        placeholder="Search over 10,000 Packages"
                        aria-label="Site search"
                        type="text"
                        autoComplete="off"
                    />
                    <button className="search__button search__button--type--submit" type="submit">
                        <Search20Svg />
                    </button>
                    <button className="search__button search__button--type--submit" type="button" onClick={handleVoiceSearch}>
                       {/* <img src='../images/microphone-svgrepo-com.svg' alt='phone'/> */}
                       {listening ? <RedMic20Svg/> : <BlackMic20Svg/>}                       
                    </button>
                    {closeButton}
                    <div className="search__border" />
                </form>

                <Suggestions className="search__suggestions" context={context} products={suggestedProducts} />
            </div>
        </div>
    );
}

export default withRouter(Search);
