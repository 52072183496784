
import React from 'react';
// import React, { useMemo } from 'react';

// third-party
// import { Helmet } from 'react-helmet-async';

// application
// import shopApi from '../../api/shop';
// import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';

// blocks
// import BlockBanner from '../blocks/BlockBanner';
// import BlockBrands from '../blocks/BlockBrands';
// import BlockCategories from '../blocks/BlockCategories';
// import BlockFeatures from '../blocks/BlockFeatures';
// import BlockPosts from '../blocks/BlockPosts';
// import Blockcat from '../blocks/Blockcat';
// import BlockProductColumns from '../blocks/BlockProductColumns';
// import BlockProducts from '../blocks/BlockProducts';
// import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
// import BlockSlideShow from '../blocks/BlockSlideShow';
import Faqs3 from "../Faqs/Faqs3";
// import Demo1Services6 from "../Demo1/Demo1Services6";
// import Demo4Banner2 from "../Demo1/Demo4Banner2";
// import Demo3Categories2 from "../Demo1/Demo3Categories2";
// import Demo3Categories3 from "../Demo1/Demo3Categories3";
// import Demo1Banner5 from "../Demo1/Demo1Banner5";
// data stubs
// import categories from '../../data/shopBlockCategories';
// import posts from '../../data/blogPosts';
// import theme from '../../data/theme';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function HomePageOne() {
    /**
     * Featured products.
     */
    // const featuredProducts = useProductTabs(
    //     useMemo(() => [
    //         { id: 1, name: 'All', categorySlug: undefined },
    //         { id: 2, name: 'Power Tools', categorySlug: 'power-tools' },
    //         { id: 3, name: 'Hand Tools', categorySlug: 'hand-tools' },
    //         { id: 4, name: 'Plumbing', categorySlug: 'plumbing' },
    //     ], []),
    //     (tab) => shopApi.getPopularProducts({ limit: 8, category: tab.categorySlug }),
    // );

    /**
     * Bestsellers.
     */
    // const bestsellers = useDeferredData(() => (
    //     shopApi.getPopularProducts({ limit: 7 })
    // ), []);

    /**
     * Latest products.
     */
    // const latestProducts = useProductTabs(
    //     useMemo(() => [
    //         { id: 1, name: 'All', categorySlug: undefined },
    //         { id: 2, name: 'Power Tools', categorySlug: 'power-tools' },
    //         { id: 3, name: 'Hand Tools', categorySlug: 'hand-tools' },
    //         { id: 4, name: 'Plumbing', categorySlug: 'plumbing' },
    //     ], []),
    //     (tab) => shopApi.getLatestProducts({ limit: 8, category: tab.categorySlug }),
    // );

    /**
     * Product columns.
     */
    // const columns = useProductColumns(
    //     useMemo(() => [
    //         {
    //             title: 'Top Rated Packages',
    //             source: () => shopApi.getTopRatedProducts({ limit: 3 }),
    //         },
    //         {
    //             title: 'Special Offers',
    //             source: () => shopApi.getDiscountedProducts({ limit: 3 }),
    //         },
    //         {
    //             title: 'Best Selling',
    //             source: () => shopApi.getPopularProducts({ limit: 3 }),
    //         },
    //     ], []),
    // );

    return (
        
        <React.Fragment>
           <Popup
      trigger={<span className='popup13'>Bank EMI starting from AED 624/month <span className="popup12">View Plans</span></span>}
      modal
      nested
    >
      {close => (
        <div className="modal model1">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header">Payment Options</div>
          <div className="content">
          <Faqs3 />
          </div>
         
        </div>
      )}
    </Popup>
          
           
        </React.Fragment>
    );
}

export default HomePageOne;
