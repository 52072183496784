import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import RatingsX from "../shared/RatingsX";
import AsyncAction from '../shared/AsyncAction';
import { cartAddItem } from '../../store/cart';
import Time from "../utils/time2";
import { percentage, daysLeft, daysLeft2 } from "../utils/percentage";

const Demo4Banner2 = ({ weeklydeal,isLogin,cartAddItem }) => {
  const endDate = weeklydeal && weeklydeal.weeklyDeals ? weeklydeal.weeklyDeals.end : null;
  const image = weeklydeal && weeklydeal.images && weeklydeal.images.length > 0 ? weeklydeal.images[0].url : "";
  // const endDate = "2022-07-17 00:00:00";

  return (
    <>
      {weeklydeal && daysLeft2(endDate) >= 0 && (
        <section id="banner-2" className="bg-05 wide-60 banner-section">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="banner-2-txt white-color text-center">
                  <h3 className="h3-sm">Deal Of the Week</h3>
                  <p className="p-sm">Get this amaizing package for the competative price in UAE - save upto 50% </p>
                  <br></br>
                </div>
              </div>
            </div>
           
            <div className="row d-flex align-items-center">
              {/* REGISTER FORM */}
              <div className="col-md-5">
                <div className="course-data text-center">
                  {/* Image */}
                  <Link to={`/shop/products/${weeklydeal._id}`}>
                    <img className="img-fluid deal1 " src={image} alt="course-preview" />
                    </Link>
                  {/* Course Price */}
                  <div className="course-data-price bg12 text-center">
                    AED {weeklydeal && weeklydeal.offerPrice}
                    <span className="old-price">AED {weeklydeal && weeklydeal.salePrice}</span>
                    <p className="p-sm">{daysLeft(endDate)}</p>
                    <div className="block-banner__button">
                      <span className="btn btn-lg btn-primary">{percentage(weeklydeal.salePrice, weeklydeal.offerPrice)}% OFF</span>
                    </div>                   
                   
                    <div className="course-rating clearfix">
                    <RatingsX ratings={weeklydeal.ratings} />
                      <span className="ratr">
                      {weeklydeal.ratings.length} Ratings
                      </span>
                      <p className="p-sm">Earn : 500 Points</p>
                    </div>
                  </div>
                </div>

                <p className="p-sm white-color ">Bank Account Options:</p>
                <img className="img-fluid deal1 " src="images/products/bank.jpg" alt="course-preview" />
              </div>
              {/* END REGISTER FORM */}
              {/* BANNER TEXT */}
              <div className="col-md-7">
                <div className="banner-2-txt white-color">
                  {/* Title */}
                  <div className="banner-2-txt white-color">
                    <h3 className="h5-sm">
                      {/* {weeklydeal && JSON.stringify(weeklydeal)} */}

                      {weeklydeal && weeklydeal.title}
                    </h3>
                  </div>
                  {/* COUNTDOWN ELEMENT */}

                  <div className="countdown">
                    {/* <span className="countdown-txt">Ends in</span> */}

                    <div id="clock">
                      <div className="cbox clearfix">
                        <span className="cbox-digit">{Time(`${endDate} 24:00:00`).days}</span> <span className="cbox-txt">d</span>
                      </div>
                      <div className="cbox clearfix">
                        <span className="cbox-digit">{Time(`${endDate} 24:00:00`).hours}</span> <span className="cbox-txt">h</span>
                      </div>
                      <div className="cbox clearfix">
                        <span className="cbox-digit">{Time(`${endDate} 24:00:00`).minutes}</span> <span className="cbox-txt">m</span>
                      </div>
                      <div className="cbox clearfix">
                        <span className="cbox-digit">{Time(`${endDate} 24:00:00`).seconds}</span> <span className="cbox-txt">s</span>
                      </div>
                    </div>
                  </div>

                  <div className="course-data-price text-center">
                    Sold : {weeklydeal.sold}
                    <div className="block-banner__button">
                    {isLogin ?
                    <AsyncAction
                        action={() => cartAddItem(weeklydeal)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-lg btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}                                                                       
                                >
                                   Buy Now
                                </button>                               
                            </React.Fragment>
                        )}
                    />:
                    <button  type="button"   disabled
                      className={classNames('btn btn-lg btn-primary product-card__addtocart', )}  >
                          Buy Now
                    </button>  
                    }
                      {/* <span className="btn btn-lg btn-primary">Buy Now</span> */}
                    </div>
                    <div className="course-rating clearfix">
                      <p className="p-sm">views : {weeklydeal.views}</p>
                    </div>
                  </div>
                </div>

                <p className="p-sm white-color ">Work Flow</p>
                <img className="img-fluid  " src="images/products/workflow2.png" alt="course-preview" />
                {/* End row */}
              </div>
            </div>
            {/* End row */}
          </div>
          {/* End container */}
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLogin:state.auth.isLogin,
});

const mapDispatchToProps = {
  cartAddItem,  
};



export default connect(  mapStateToProps,  mapDispatchToProps,)(Demo4Banner2);
