import { toast } from "react-toastify";
import authApi from "../../api/auth";
import { LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_FAILURE,LOGOUT_SUCCESS,
  CHANGE_MSG_COUNT } from "./userActionTypes";
import {WISHLIST_UPDATE_ITEM} from '../wishlist/wishlistActionTypes'

export function login(user) {
  return (dispatch) => {
    dispatch(request());
    return authApi.login(user).then(
      (data) => {
        dispatch(success({user:data.user, token:data.token}));
        dispatch({type:WISHLIST_UPDATE_ITEM, wishlist:data.wishlist});
        // console.log(data);
        return Promise.resolve(data);
      },
      (err) => {
        dispatch(failure(err));
        toast.error(`${err}`, { theme: "colored" });        
      }
    );
  }; 

  function request() {
    return { type: LOGIN_REQUEST };
  }
  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
}

export function registerApi(user,history=null) {
  return (dispatch) => {
    dispatch(request());
    return authApi.register(user).then(
      (data) => {
        // console.log(data);
        dispatch(success({user:data.user, token:data.token}));
        dispatch({type:WISHLIST_UPDATE_ITEM, wishlist:data.wishlist ?? []});        
        // console.log(data);
        toast.success(`Hi ${data.user.firstName}`,{theme:'colored'})
        history && history.push('/')
        return Promise.resolve(data)
      },
      (err) => {
        dispatch(failure(err));
        toast.error(`${err}`, { theme: "colored" });        
      }
    );
  }; 

  function request() {
    return { type: LOGIN_REQUEST };
  }
  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
}

export function logout(){
  return (dispatch) =>{
    dispatch({type:LOGOUT_SUCCESS})
  }  
}

export function changeMsgCount(){
  return (dispatch)=>{
    dispatch({type:CHANGE_MSG_COUNT})
  }
}