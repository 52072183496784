import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM } from './wishlistActionTypes';
import authApi from '../../api/auth';

export function wishlistAddItemSuccess(product) {
    toast.success(`Product "${product.title}" added to wish list!`, { theme: 'colored' });

    return {
        type: WISHLIST_ADD_ITEM,
        product,
    };
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

// export function wishlistAddItem(product) {
//     // sending request to server, timeout is used as a stub
//     return (dispatch) => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(wishlistAddItemSuccess(product));
//                 resolve();
//             }, 500);
//         })
//     );
// }

export function wishlistAddItem(product) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            authApi.addWishlist({pId:product._id}).then(
                (res)=>{
                    dispatch(wishlistAddItemSuccess(product));
                    resolve();
                    console.log(res)
                    }
            )           
        })
    );
}

export function wishlistRemoveItem(productId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            authApi.removeWishlist({pId:productId}).then(
                res=>{
                    dispatch(wishlistRemoveItemSuccess(productId));
                    resolve();
                    console.log(res)
                }
            )           
        })
    );
}

// export function wishlistRemoveItem(productId) {
//     // sending request to server, timeout is used as a stub
//     return (dispatch) => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(wishlistRemoveItemSuccess(productId));
//                 resolve();
//             }, 500);
//         })
//     );
// }
