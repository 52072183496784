// react
import React,{useState} from 'react';
import {useForm} from 'react-hook-form';
import { toast } from 'react-toastify';
import bankApi from '../../api/bank';

// application
import SocialLinks from '../shared/SocialLinks';

export default function FooterNewsletter() {

    const [submitting, setSubmitting] = useState(false);
    const {register,handleSubmit,errors,reset} = useForm();

    const formSubmit = (fd) =>{       
        
        setSubmitting(true)
        bankApi.sendEnquiry(fd).then(
        res=>{
            // console.log(res);
            toast.success(`${res.msg}`, { theme: 'colored' });
            setSubmitting(false)
            reset({email:""})
        },
        err=>{
        // console.log(err);
        toast.error(`${err}`, { theme: 'colored' });
        setSubmitting(false)
        
        })    
    }
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">
                Receive news and promotions by email from myuaestartup. You can unsubscribe whenever you want.
            </div>

            <form onSubmit={handleSubmit(formSubmit)} className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>
                <input
                    type="text" name="email"
                    ref={register({required:"This field is required"})}
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                />
                <button type="submit" className="footer-newsletter__form-button btn btn-primary footersub">
                    {submitting ? <span className="spinner-border spinner-border-sm" ></span>: "Subscribe" }
                </button>               
                
            </form>
            <div>{errors.email && <small className="text-white font-italic">{errors.email?.message}</small>}</div>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Follow us on social networks
            </div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
