
// myuae startup - front end


// export const url = "http://localhost:4000/v1"

// export const fixerUrl ="http://data.fixer.io/api/latest?access_key=05b195861f12462b6aa6bc277e07df24&symbols=EUR,INR,AED,USD"



export const url = "https://myuaestartup.com/api/v1";

export const fixerUrl ="https://data.fixer.io/api/latest?access_key=05b195861f12462b6aa6bc277e07df24&symbols=EUR,INR,AED,USD"
