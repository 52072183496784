// react
import React from 'react';
// import {Link} from 'react-router-dom'

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
// import theme from '../../data/theme';

export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '' },
        { title: 'Contact Us', url: '' },
        { title: 'Privacy Policy', url: '/site/privacy' },
        { title: 'Terms and Conditions', url: '/site/terms' },             
               
    ];

    const informationLinks1 = [
        { title: 'Free Zone', url: '' },        
        { title: 'Freelance', url: '' },
        
       
    ];
    const informationLinks2 = [
        { title: 'Become an Agent', url: '/account/agent-register' },
        { title: 'Become a vendor', url: '/account/vendor-register' }, 
    ];
    const accountLinks = [
        { title: 'My Account', url: '/account/dashboard' },
        { title: 'Order History', url: '/account/orders' },
        { title: 'Wish List', url: '/shop/wishlist' },        
        { title: 'Compare', url: '/shop/compare' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} />
                           
                           
                        </div>
                        
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />

                            
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Our  Categories" items={informationLinks1} />
                            <FooterLinks title="Login / Register" items={informationLinks2} />
                            
                        </div>
                       
                        <div className="col-12 col-md-12 col-lg-3">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by Cyfrowy
                        {' '}
                        {/* <a  rel="noopener noreferrer" target="_blank">Cyfrowy</a> */}
                        {' '}
                        
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
