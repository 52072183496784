import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";
import { authHeader } from "../_heleper/auth-header";

const searchApi = {

  getCategories: () =>{
    const reqOptions = {method:"GET"}
    return fetch(`${url}/category/lists`,reqOptions)
      .then(responseHandler)
  },
  getWorkflows: () => {
    const reqOptions = { method: "GET"};
    return fetch(`${url}/workflow/list`, reqOptions)
      .then(responseHandler)
  },

  getEmirates: (catId) => {
    const reqOptions = {method:"GET"}
    return fetch(`${url}/emirate/match-list/${catId}`,reqOptions)
      .then(responseHandler)
  },

  getZones: (catId, emiId) => {
    const reqOptions = {method:"GET"}
    return fetch(`${url}/zone/match-list/${catId}/${emiId}`,reqOptions)
      .then(responseHandler)
  },

  getNorthernZones: (catId, emiId) => {
    const reqOptions = {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify({catId,emiId})
    }
    return fetch(`${url}/zone/match-list/northern`,reqOptions)
      .then(responseHandler)
  }, 
  
  getNorthernLicenses: (catId, emiId) => {
    const reqOptions = {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify({catId,emiId})
    }
    return fetch(`${url}/license/match-list/northern`,reqOptions)
      .then(responseHandler)
  }, 

  getLicenses: (catId, emiId) => {
    const reqOptions = {method:"GET"}
    return fetch(`${url}/license/match-list/${catId}/${emiId}`,reqOptions)
      .then(responseHandler)
  }, 

  // pass only licid
  getSubLicenses: (licId,emiId,catId) => {
    const reqOptions = {method:"GET"}
  return fetch(`${url}/sub-license/list/parent/${licId}/${emiId}/${catId}`,reqOptions)
      .then(responseHandler)
  },
  
  // pass only sublic id
  getActivityGroup: (subLicId,zoneId) => {
    const reqOptions = {method:"GET"}
    return fetch(`${url}/activity-group/list/parent/${subLicId}/${zoneId}`,reqOptions)
      .then(responseHandler)
  }, 

  // pass only sublic id
  getActivities: (subLicId,licId,emiId,catId) => {
    const reqOptions = {method:"GET"}
    return fetch(`${url}/activity/list/parent/${subLicId}/${licId}/${emiId}/${catId}`,reqOptions)
      .then(responseHandler)
  }, 

  // pass only actId
  getSubActivities :(actId,subLicId,licId,emiId, catId) =>{
    const reqOptions = {method:"GET"}
    return fetch(`${url}/sub-activity/list/parent/${actId}/${subLicId}/${licId}/${emiId}/${catId}`,reqOptions)
      .then(responseHandler)
  },

  getNorthSubActivities :(actId) =>{
    const reqOptions = {method:"POST", headers:{"Content-Type":"application/json"},
  body:JSON.stringify({actId})}
    return fetch(`${url}/sub-activity/list/parent/northern`,reqOptions)
      .then(responseHandler)
  },

  addPackage: (data) => {
    const reqOptions = {method:"POST",
        headers:{...authHeader(),"Content-Type": "application/json"},
        body:JSON.stringify(data),}
  
        return fetch(`${url}/product/add-vendor`,reqOptions)
        .then(responseHandler)
  },

  listPackage: (page,limit,data={}) =>{
    const reqOptions = {method:"POST", body:JSON.stringify(data),
            headers:{...authHeader(),"Content-Type":"application/json"}}

    return fetch(`${url}/product/list-vendor?limit=${limit}&page=${page}`,reqOptions).then(responseHandler)
  },
 
  
};

export default searchApi;
