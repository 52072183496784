export function responseHandler(response){
   
    return response.text().then(text =>{
        if(response.status === 404){
            return Promise.reject({err:"Not Found"}); 
        }
        
        const data = text && JSON.parse(text);
        if(!response.ok){
            const error = (data && data.err) || response.statusText;
            return Promise.reject(error);
        }

        return Promise.resolve(data);
    })
}