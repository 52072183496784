// react
import React from 'react';

// third-party

import PropTypes from 'prop-types';
import StarRating from "react-svg-star-rating";


function RatingsX(props) {
    const { ratings } = props;
    let stars = ratings.map(x=>x.star);
    let value = (stars.reduce((x,y)=>(x+y),0))/stars.length || 0;


    return (
        <div className="rating">
            <div className="rating__body">
                <StarRating isReadOnly initialRating={value}
                    unit="half" size={18}
                    containerClassName="d-flex" />
            </div>
        </div>

    );
}

RatingsX.propTypes = {
    /**
     * rating value
     */
    ratings: PropTypes.array,
};
RatingsX.defaultProps = {
    ratings: 0,
};

export default RatingsX;
