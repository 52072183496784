
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// import Dropdown from './Dropdown';
import DropdownCurrency from './DropdownCurrency';
// import DropdownLanguage from './DropdownLanguage';

function Topbar() {
    const links = [
        { title: <FormattedMessage  id="topbar.aboutUs" defaultMessage="Dubai Silicon Oasis Authority -  Two Visa Package 20% off hurry up" />, url: '' },
       
    ];

    // const accountLinks = [
    //     { title: 'Dashboard', url: '' },
    //     { title: 'Edit Profile', url: '' },
    //     { title: 'Order History', url: '' },
    //     { title: 'Addresses', url: '' },
    //     { title: 'Password', url: '' },
    //     { title: 'Logout', url: '' },
    // ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link className="topbar-link" to={item.url}>{item.title}</Link>
        </div>
    ));

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring" />
                    {/* <div className="topbar__item">
                        <Dropdown
                            title={<FormattedMessage id="topbar.myAccount" defaultMessage="My Account" />}
                            items={accountLinks}
                        />
                    </div> */}
                    <div className="topbar__item">
                        <DropdownCurrency />
                    </div>
                    {/* <div className="topbar__item">
                        <DropdownLanguage />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Topbar;
