
import React from "react";
import { Link } from "react-router-dom";



export default function CatHome({data}) {  

  const makeUrl = (value,title) =>{
    let url = `/package/catalog/?type=all`
    const item = data?.categories?.find(cat=>cat.value === value);
    if(item) 
    url =  `/package/catalog/?type=cat&cat=${item._id}`;

    return <Link to={url} className="btn btn-tra-grey rose-hover">  {title}  </Link>
  }

  return (
    <section id="contacts-2" className="wide-100 contacts-section division">
      <div className="container ">        
        <div className="row">
          <div className="col-md-12">
            <div className="section-title title-centered mb-10">              
              <h3 className="h3-sm ">Our Business Setup Packages</h3>              
              <p className="p-md">
              At Capital International Group, we offer two major packages for business setup in the UAE: the Freezone Package and the Freelance Package.
              </p>
            </div>
          </div>
        </div>
        <div className="contacts-2-holder">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="contact-box b-right">
                <img className="img-75" src="https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/freezone.png" alt="contacts-icon" />
                <h5 className="h5-md">Freezone Package</h5>
                <p>
                Our Freezone Package is ideal for entrepreneurs looking for 100% ownership of their business. With this package,
                 you can benefit from tax exemptions, 100% repatriation of profits, and no currency restrictions. 
                 Additionally, you can enjoy state-of-the-art infrastructure and business support services within the free zone.
                </p>
                {makeUrl('fz','Freezone Packages')}                  
                
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-box">
                <img className="img-75" src="https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/freelance.png" alt="contacts-icon" />
                <h5 className="h5-md">Freelance Package</h5>
                <p>
                For those seeking flexibility and independence, our Freelance Package is the perfect choice. This package allows you to operate as a sole practitioner, offering services in your field of expertise.
                 Enjoy the freedom to work from anywhere, with no office space requirements or corporate taxes.
                </p>
                {makeUrl('fl','Freelance Packages')}                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
