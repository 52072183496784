
import React from "react";

const TestimonialSliderCon  = ({post}) => {
    return (
        <section
            id="categories-2"
            className="categories-section division"
        >
            <div className="">
                <div className="row">
                    {/* CATEGORIE BOX #1 */}
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="review-4">
                  <div className="review-4-txt">
                    {/* Text */}
                    <p>
                     {post.content}
                    </p>
                    {/* Author Data */}
                    <div className="review-4-author d-flex align-items-center">
                      {/* Author Avatar */}
                      <div className="author-avatar">
                        <img
                          className="img-fluid"
                          src={post.avatar.url}
                          alt="review-author-avatar"
                        />
                      </div>
                      {/* Testimonial Author */}
                      <div className="review-author">
                        {/* Rating */}
                        <div className="tst-rating">
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                        </div>
                        <h5 className="h5-xs">{post.name}</h5>
                        <span>{post.position}</span>
                      </div>
                    </div>
                  </div>
                </div>
                    </div>
                    {/* END CATEGORIE BOX #1 */}
                    
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default TestimonialSliderCon;
