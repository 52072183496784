import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import { categoriesListData, prepareCategory } from './categories';

const getId = makeIdGenerator();

const attributesDef = [
    
    
    {
        name: 'sold',
        slug: 'sold',
        values: [
            { name: 'sold', slug: 'sold' },
        ],
    },
    {
        name: '1',
        slug: '1',
        values: [
            { name: '100% foreign ownership', slug: '100% foreign ownership' },
        ],
    },
    {
        name: '2',
        slug: '2',
        values: [
            { name: '0% corporate and personal income tax', slug: '0% corporate and personal income tax' },
        ],
    },
    {
        name: '3',
        slug: '3',
        values: [
            { name: '100% repatriation of capital and profitsium', slug: '100% repatriation of capital and profitsium' },
        ],
    },
    {
        name: '4',
        slug: '4',
        values: [
            { name: 'No currency restrictions', slug: 'No currency restrictions' },
        ],
    },
    
];

const productsDef = [
    {
        slug: 'Ajman free zone - Two Visa Package',
        name: 'Ajman free zone - Two Visa Package',
        price: 849,
        images: [
            'images/products/product-1.jpg',
            'https://www.youtube.com/watch?v=YYMY5dOPV1g',
        ],
        badges: 'saver',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'Freelance',
        categories: ['mainland'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: '1', values: '100% foreign ownership', featured: true },
            { slug: '2', values: '0% corporate and personal income tax', featured: true },
            { slug: '3', values: '100% repatriation of capital and profitsium', featured: true },
            { slug: '4', values: 'No currency restrictions', featured: true },
           
            
        ],
    },
    {
        slug: 'ram Two Visa Package',
        name: 'Ram - Two Visa Package',
        price: 849,
        images: [
            'images/products/product-1.jpg',
            'https://www.youtube.com/watch?v=YYMY5dOPV1g',
        ],
        badges: 'saver',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'Freelance',
        categories: ['mainland'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: '1', values: '100% foreign ownership', featured: true },
            { slug: '2', values: '0% corporate and personal income tax', featured: true },
            { slug: '3', values: '100% repatriation of capital and profitsium', featured: true },
            { slug: '4', values: 'No currency restrictions', featured: true },
           
            
        ],
    },
    {
        slug: 'Dubai Silicon Oasis Authority - Two Visa Package',
        name: 'Dubai Silicon Oasis Authority - Two Visa Package',
        price: 749,
        images: [
            'images/products/product-2.jpg',
            'images/products/product-2-1.jpg',
        ],
        badges: 'saver',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'Freelance',
        categories: ['mainland'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: '1', values: '100% foreign ownership', featured: true },
            { slug: '2', values: '0% corporate and personal income tax', featured: true },
            { slug: '3', values: '100% repatriation of capital and profitsium', featured: true },
            { slug: '4', values: 'No currency restrictions', featured: true },
           
            
        ],
    },
    {
        slug: 'Rakicc Authority - Two Visa Package',
        name: 'Rakicc  Authority - Two Visa Package',
        price: 949,
        images: [
            'images/products/product-3.jpg',
            'images/products/product-3-1.jpg',
        ],
        badges: 'saverplus',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'Freelance',
        categories: ['mainland'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: '1', values: '100% foreign ownership', featured: true },
            { slug: '2', values: '0% corporate and personal income tax', featured: true },
            { slug: '3', values: '100% repatriation of capital and profitsium', featured: true },
            { slug: '4', values: 'No currency restrictions', featured: true },
           

            
        ],
    },
    
    {
        slug: 'Bnp Paribas - Two Visa Package',
        name: 'Bnp Paribas - Two Visa Package',
        price: 949,
        images: [
            
            'images/products/product-4.jpg',
            'images/products/product-4-1.jpg',
            
        ],
        badges: 'flexi',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'Freelance',
        categories: ['mainland'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: '1', values: '100% foreign ownership', featured: true },
            { slug: '2', values: '0% corporate and personal income tax', featured: true },
            { slug: '3', values: '100% repatriation of capital and profitsium', featured: true },
            { slug: '4', values: 'No currency restrictions', featured: true },
           
            
        ],
    },
    {
        slug: 'Ajman free zone - Two Visa Package',
        name: 'Ajman free zone - Two Visa Package',
        price: 849,
        images: [
            'images/products/product-1.jpg',
            'images/products/product-1-1.jpg',
        ],
        badges: 'flexiplus',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'Freelance',
        categories: ['mainland'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: '1', values: '100% foreign ownership', featured: true },
            { slug: '2', values: '0% corporate and personal income tax', featured: true },
            { slug: '3', values: '100% repatriation of capital and profitsium', featured: true },
            { slug: '4', values: 'No currency restrictions', featured: true },
           
            
        ],
    },


    
];

const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === 'string' ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || []).map((productAttributeDef) => {
        const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

        if (!attributeDef) {
            return null;
        }

        let valuesDef = [];

        if (typeof productAttributeDef.values === 'string') {
            valuesDef = [productAttributeDef.values];
        } else if (productAttributeDef.values) {
            valuesDef = productAttributeDef.values;
        }

        const values = valuesDef.map((valueSlug) => {
            const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

            if (!valueDef) {
                return null;
            }

            return {
                ...valueDef,
                customFields: {},
            };
        }).filter((x) => x !== null);

        if (!values.length) {
            return null;
        }

        return {
            name: attributeDef.name,
            slug: attributeDef.slug,
            featured: !!productAttributeDef.featured,
            values,
            customFields: {},
        };
    }).filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: '83690/32',
        slug: productDef.slug,
        price: productDef.price,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
    };
});

export default productsData;
