const data = [
    {
        title: 'USD',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'USD',
        },
    },
    {
        title: 'AED',
        currency: {
            code: 'AED',
            symbol: 'AED',
            name: 'AED',
        },
    },
    // {
    //     title: '€ EUR',
    //     currency: {
    //         code: 'EUR',
    //         symbol: '€',
    //         name: 'EUR',
    //     },
    // },
    // {
    //     title: 'INR',
    //     currency: {
    //         code: 'INR',
    //         symbol: 'INR',
    //         name: 'INR',
    //     },
    // },
    
   
];

export default data;