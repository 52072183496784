// react
// import ImageGallery from 'react-image-gallery';
import React, { Component } from 'react';
// import ProductGallerynew from "../Demo1/ProductGallerynew";
// import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import popup from './popup';

// application
import languages from '../../i18n';
import StroykaSlick from './StroykaSlick';
import { ZoomIn24Svg } from '../../svg';
import VideoThumb from '../../svg/video-thumb.png'
import { addonsAddItem } from '../../store/addons';

const slickSettingsFeatured = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};
const slickSettingsThumbnails = {
    standard: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1199, settings: { slidesToShow: 4 } },
            { breakpoint: 991, settings: { slidesToShow: 3 } },
            { breakpoint: 767, settings: { slidesToShow: 5 } },
            { breakpoint: 479, settings: { slidesToShow: 4 } },
            { breakpoint: 379, settings: { slidesToShow: 3 } },
        ],
    },
    sidebar: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1199, settings: { slidesToShow: 3 } },
            { breakpoint: 767, settings: { slidesToShow: 5 } },
            { breakpoint: 479, settings: { slidesToShow: 4 } },
            { breakpoint: 379, settings: { slidesToShow: 3 } },
        ],
    },
    columnar: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1199, settings: { slidesToShow: 3 } },
            { breakpoint: 767, settings: { slidesToShow: 5 } },
            { breakpoint: 479, settings: { slidesToShow: 4 } },
            { breakpoint: 379, settings: { slidesToShow: 3 } },
        ],
    },
    quickview: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1199, settings: { slidesToShow: 4 } },
            { breakpoint: 767, settings: { slidesToShow: 5 } },
            { breakpoint: 479, settings: { slidesToShow: 4 } },
            { breakpoint: 379, settings: { slidesToShow: 3 } },
        ],
    },
};

const additional = [
    {title: 'Call Answering Service',offerPrice:500,_id:"custom001",pack:'addi',
     images:[{key:'upload/images/call-service.jpg',url:'https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/call-service.jpg'}],},
    {title: 'Company Bank Account',  offerPrice:500,_id:"customb002",pack:'addi',
    images:[{key:'upload/images/company-bank.jpg',url:'https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/company-bank.jpg'}],},
    {title: 'Dedicated Phone Number',  offerPrice:500,_id:"custom003",pack:'addi',
    images:[{key:'upload/images/phone-number.jpg',url:'https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/phone-number.jpg'}],},
    {title: 'Dedicated Metting Rooms',  offerPrice:500,_id:"custom004",pack:'addi',
    images:[{key:'upload/images/meet-room.jpg',url:'https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/meet-room.jpg'}],},
    {title: 'Dedicated Website',  offerPrice:500,_id:"custom005",pack:'addi',
    images:[{key:'upload/images/website.jpg',url:'https://cyfrowy-bucket.s3.us-west-1.amazonaws.com/upload/images/website.jpg'}],},
]



class ProductGallery extends Component {

    gallery;    
    createGallery = null;
    imagesRefs = [];
    unmounted = false;

    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
            transition: false,
            player: false,
        };
    }

    componentDidMount() {
        this.createGallery = import('../../photoswipe').then((module) => module.createGallery);

        // this is necessary to reset the transition state, because sometimes
        // react-slick does not trigger an afterChange event after a beforeChange event
        setTimeout(() => {
            this.setState(() => ({
                transition: false,
            }));
        }, 0);
    }

    componentDidUpdate(prevProps) {
        const { locale: prevLocale } = prevProps;
        const { direction: prevDirection } = languages[prevLocale];
        const { locale: currLocale } = this.props;
        const { direction: currDirection } = languages[currLocale];

        if (currDirection !== prevDirection) {
            // this is necessary to reset the transition state,
            // because when the direction changes, the afterChange event does not fire
            setTimeout(() => {
                this.setState(() => ({
                    transition: false,
                }));
            }, 0);
        }
    }

    componentWillUnmount() {
        if (this.gallery) {
            this.gallery.destroy();
        }

        this.unmounted = true;
    }

    getIndexDependOnDir(index) {
        const { images, locale } = this.props;
        const { direction } = languages[locale];

        // we need to invert index id direction === 'rtl' due to react-slick bug
        if (direction === 'rtl') {
            return images.length - 1 - index;
        }

        return index;
    }

    handleFeaturedClick = (event, index) => {
        const { layout } = this.props;

        if (!this.createGallery || layout === 'quickview') {
            return;
        }

        event.preventDefault();

        this.openPhotoswipe(index);
    };

    handleThumbnailClick = (index, type) => {
        const { transition } = this.state;

        if (transition) {
            return;
        }

        if(type==="video"){
            this.setState(() => ({ currentIndex: index, player:true }));
        }
        else{
            this.setState(() => ({ currentIndex: index, player:false }));
        }
      

        if (this.slickFeaturedRef) {
            this.slickFeaturedRef.slickGoTo(this.getIndexDependOnDir(index));
        }
    };

    handleFeaturedBeforeChange = (oldIndex, newIndex) => {
        this.setState(() => ({
            currentIndex: this.getIndexDependOnDir(newIndex),
            transition: true,
        }));
    };

    handleFeaturedAfterChange = (index) => {
        this.setState(() => ({
            currentIndex: this.getIndexDependOnDir(index),
            transition: false,
        }));
    };

    handleZoomButtonClick = () => {
        const { currentIndex } = this.state;

        this.openPhotoswipe(currentIndex);
    };

    setSlickFeaturedRef = (ref) => {
        this.slickFeaturedRef = ref;
    };

    openPhotoswipe(index) {
        const { images, locale } = this.props;
        const { direction } = languages[locale];

        const items = this.imagesRefs.map((tag, index) => {
            const width = parseFloat(tag.dataset.width) || tag.naturalWidth;
            const height = parseFloat(tag.dataset.height) || tag.naturalHeight;

            return {
                src: images[index].url,
                msrc: images[index].url,
                w: width,
                h: height,
            };
        });

        if (direction === 'rtl') {
            items.reverse();
        }

        // noinspection JSUnusedGlobalSymbols
        const options = {
            getThumbBoundsFn: (index) => {
                const dirDependentIndex = this.getIndexDependOnDir(index);

                if (!this.imagesRefs[dirDependentIndex]) {
                    return null;
                }

                const tag = this.imagesRefs[dirDependentIndex];
                const width = tag.naturalWidth;
                const height = tag.naturalHeight;
                const rect = tag.getBoundingClientRect();
                const ration = Math.min(rect.width / width, rect.height / height);
                const fitWidth = width * ration;
                const fitHeight = height * ration;

                return {
                    x: rect.left + (rect.width - fitWidth) / 2 + window.pageXOffset,
                    y: rect.top + (rect.height - fitHeight) / 2 + window.pageYOffset,
                    w: fitWidth,
                };
            },
            index: this.getIndexDependOnDir(index),
            bgOpacity: 0.9,
            history: false,
        };

        this.createGallery.then((createGallery) => {
            if (this.unmounted) {
                return;
            }

            this.gallery = createGallery(items, options);

            this.gallery.listen('beforeChange', () => {
                if (this.gallery && this.slickFeaturedRef) {
                    this.slickFeaturedRef.slickGoTo(
                        this.gallery.getCurrentIndex(),
                        true,
                    );
                }
            });
            this.gallery.listen('destroy', () => {
                this.gallery = null;
            });

            this.gallery.init();
        });
    }

     handleAdditional = (item,checked) =>{
        this.props.addonsAddItem(item)
    }

    render() {
        const { layout, images, product, addon, } = this.props;
        const { currentIndex, player } = this.state;

        const sortedImages = images.sort((a,b)=>a.type.localeCompare(b.type))
        
        const featured = sortedImages.map((image, index) => (


            <div key={index} className="product-image product-image--location--gallery">
               {image.type === "video" ? <VideoPlayer url={image.url} player={player}/> : 
               <Link 
               to={`/${image.url}`}
               className="product-image__body"
               onClick={(event) => this.handleFeaturedClick(event, index)}
               target="_blank"
           >
               
               <img
                   className="product-image__img"
                   src={image.url}
                   alt=""
                   ref={(element) => { this.imagesRefs[index] = element; }}
                   data-width="700"
                   data-height="700"
               />
              </Link>}
            </div>
            
            
        ));


        const thumbnails = sortedImages.map((image, index) => {
            const classes = classNames('product-gallery__carousel-item product-image', {
                'product-gallery__carousel-item--active': index === currentIndex,
            });

            return (
                <button
                    type="button"
                    key={index}
                    onClick={() => this.handleThumbnailClick(index, image.type)}
                    className={classes}
                >
                    <div className="product-image__body">
                        <img className="product-image__img product-gallery__carousel-image"
                         src={image.type === "video" ? VideoThumb : image.url} alt="" />
                       
                    </div>
                </button>
            );
        });

        return (

       
            <div className="product__gallery">

                <div className="product-gallery">
                    <div className="product-gallery__featured">
                        {layout !== 'quickview' && (
                            <button type="button" className="product-gallery__zoom" onClick={this.handleZoomButtonClick}>
                                <ZoomIn24Svg />
                            </button>
                        )}
                        <StroykaSlick
                            ref={this.setSlickFeaturedRef}
                            {...slickSettingsFeatured}
                            beforeChange={this.handleFeaturedBeforeChange}
                            afterChange={this.handleFeaturedAfterChange}
                        >
                            {featured}
                        </StroykaSlick>
                    </div>
                    <div className="product-gallery__carousel">
                        <StroykaSlick {...slickSettingsThumbnails[layout]}>
                            {thumbnails}
                        </StroykaSlick>
                    </div>
                </div><br></br>
                {/* <div><ImageGallery items={images1} /></div> */}
                {/* <ProductGallerynew/> */}
                {product.category?.value === "fz" ?
                    <div>
                    <h5>Package Key Highlights</h5>
                    <ul className='pack1'>
                    <li><b>License : </b><span className="package-highlist">{product.license?.label ?? "NA"}</span></li>
                    <li><b>Activity Group : </b><span className="package-highlist">{product.subLicense?.label ?? "NA"}</span></li>
                    <li><b>Activity : </b> <span className="package-highlist">{product.activity?.label ?? "NA"} </span></li>
                    <li><b>Sub Activity : </b> <span className="package-highlist">{product.subActivity?.label ?? "NA"} </span></li>                        
                    <li><b>Share holder : </b> <span className="package-highlist">{product.shareNo ?? "NA"}</span></li>
                    <li><b>Investor Visa : </b> <span className="package-highlist">{product.investorVisa ?? "NA"}</span></li>
                    <li><b>Employment Visa : </b> <span className="package-highlist">{product.employmentVisa ?? "NA"}</span></li>                    
                    <li><b>Office Type : </b> <span className="package-highlist">{product.officeType?? "NA"} </span></li>                       
                    </ul>
                    </div> :<div> 
                    <h5>Package Key Highlights</h5>
                    <ul className='pack1'>                   
                    <li><b>Activity Group : </b><span className="package-highlist">{product.subLicense?.label ?? "NA"}</span></li>                    
                    <li><b>Activity : </b> <span className="package-highlist">{product.subActivity?.label ?? "NA"} </span></li>                    
                    <li><b>Visa : </b> <span className="package-highlist">{product.visaNo ?? "NA"}</span></li>
                    <li><b>Visa Validity : </b> <span className="package-highlist">{product.visaValidity ?? "NA"}</span></li>                  
                    </ul>
                    </div> }
                    {product.category?.value === "fz" && <div>
                    <h5>Additional Addons</h5>
                    <ul className='pack1'>
                        {additional?.map((item,idx)=>
                       <div className='form-check' key={idx}>
                            <input className='form-check-input' type='checkbox' value="500" id={`adc${idx}`}
                            onChange={(e)=>this.handleAdditional(item,e.target.checked)}
                            checked={addon.some(tx=>tx._id === item._id )}
                            ></input>
                            <label className='form-check-label' htmlFor={`adc${idx}`}>{item.title}</label>
                        </div> )}                 
                        
                    </ul>
                    </div>}
            </div>
            
            
        );
    }
}

ProductGallery.propTypes = {
    images: PropTypes.array,
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /** current locale */
    locale: PropTypes.string,
    product: PropTypes.object,
};

ProductGallery.defaultProps = {
    images: [],
    layout: 'standard',
    product:{},
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    addon: state.addon,
});
const mapDispatchToProps = {
    addonsAddItem
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductGallery);


const VideoPlayer = ({player, url}) => {    

    return(
        <div className="product-image__body" >
                   <div className="product-image__img">
                       
                       {player &&
               <iframe src={url} title="youtube video" height="100%"></iframe>
                             
                       }
               </div>
               </div>

    )
}