// react
import React, {useState,useRef} from 'react';

// third-party
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify';
// import { Compare16Svg } from '../../svg';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';

// import authApi from '../../api/auth';
// import { toast } from 'react-toastify';
import {registerApi} from '../../store/user'
import {registerClose} from '../../store/register'
import {login} from '../../store/user'

// import { LogoSvg } from '../../svg';

const iconstyle = {
    fontSize: "32px",color: "white",top: "50%",position: "absolute",
    left: "50%",transform: "translate(-50%, -50%)"
}



function Header(props) {
    const { layout, dept, hdata } = props;
    let bannerSection;
    const [userData, setUserData] = useState({ username: "", password: "" });
    // const [show, setShow] = useState(true);
    const [phone, setPhone] = useState('');
    const [error,setError] = useState(null);
    const [active,setActive] = useState(2)   
    const password = useRef();
    const dispatch = useDispatch();
    const {register,handleSubmit,errors,watch,reset} = useForm();
    const auth = useSelector(state=>state.auth)
    const regipop = useSelector(state=>state.regipop)    
    password.current = watch('password',"");    

    const handleClose = () => dispatch(registerClose());

    const handleInputChange = (e) => {
        setUserData(preState => ({ ...preState, [e.target.name]: e.target.value }))
    }

    const onFormSubmit = (formValue) =>{
        if(!phone) {
            setError('Phone No is required');
            return
        }
        setError(null)
        console.log({...formValue,phone});
        dispatch(registerApi({...formValue,phone})).then(data=>{            
                setPhone('');
                reset();   
        })
    }

    const onFormLogin = (e)=>{
        e.preventDefault()        
        // console.log(userData);
        dispatch(login(userData)).then(data=>{
            if(data && data.user){
                data && toast.success(`Hi ${data.user.firstName}`,{theme:'colored'})
            setUserData({ username: "", password: "" })
            dispatch(registerClose())
            }            
        })       
    }

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/"><img src="images/logos/my-uae-startup-logo1.svg" alt="" /></Link>
                </div>
                <div className="site-header__search tour-search">
                    <Search context="header" categories={dept} />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                    <Link type="button"  style={{height:"53px",width:"54px",borderRadius:"10px"}}                                         
                        className="btn btn-light btn-svg-icon  btn-svg-icon--fake-svg product-card__compare tooltip1 tour-plus "
                       to="/site/packages"
                    >
                        <i className="fas fa-plus-circle" style={iconstyle}></i>
                        <span className="tooltiptext" style={{width:"220px",top:"-22px",}}>Customize your packages</span>
                    </Link>
                    <Link to="/"><img src="images/logos/apple.png" alt="" /></Link>
                    <Link to="/"><img src="images/logos/android.png" alt="" /></Link>
                    
                       {/* <button className="footer-newsletter__form-button btn btn-primary"> <FormattedMessage id="header.phoneLabel2" defaultMessage="Sign in / Register" /></button> */}
                    </div>
                    {/* <div className="site-header__phone-number">
                        <FormattedMessage id="header.phone1" defaultMessage="(800) 12345678" />
                    </div> */}
                    
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <Topbar />
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} dept={dept} hdata={hdata} />
            </div>
            
            <Modal
                show={regipop.open && !auth.isLogin}
                onHide={handleClose}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='mb-4'><h6>Looking to set up business in UAE?</h6></div>
                        <section id='faqs-1' className='faq5 faqs-section division block'>
                        <div className='row'>
                        <div className='col-lg-12 head-2'>
                        <div className='tabs-nav tabs-nav1 fq2 clearfix mx-3'>
                            <ul className='tabs-1 tabs-2 faq4'>
                            <li className={classNames('tab-link',{'current':active===2})} onClick={()=>setActive(2)}>
                                    <div className='tab-link-txt tab-link-txt1'>
                                        <h5 className='h5-xs'>LOGIN</h5>
                                    </div>                                    
                                </li>
                            <li className={classNames('tab-link',{'current':active===1})}  onClick={()=>setActive(1)}>
                                    <div className='tab-link-txt tab-link-txt1'>
                                        <h5 className='h5-xs'>REGISTER</h5>
                                    </div>                                    
                                </li>
                                
                            </ul>
                        </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='tabs-content'>
                                {/* login */}
                                <div id='tab-2' className={classNames('tab-content',{'current':active===2})}>
                                <div id="register-form"> 
                            <form
                                name="registerForm"
                                className="row register-form"
                                onSubmit={onFormLogin}
                            >  
                                <div id="input-email" className="col-md-12">
                                    <p>Your Email / Phone No*</p>
                                    <input
                                        type="text"                                       
                                        className="form-control email"
                                        placeholder="Enter Your Email / Phone No"
                                        name="username"
                            value={userData.username}
                            onChange={handleInputChange}                                                                               
                                    />                                   
                                </div>
                               
                                <div id="input-password" className="col-md-12">
                                    <p>Your Password*</p>
                                    <input
                                        type="password"                                       
                                        className="form-control name"
                                        placeholder="Enter Your Password*"
                                        name="password"
                                        value={userData.password}
                                        onChange={handleInputChange}                                       
                                    />                                 
                                </div>
                                
                                {/* Form Button */}
                                <div className="col-md-12 form-btn">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-primary mt-2"
                                        disabled={userData.username === "" || userData.password === ""}
                                    >
                                     {auth.isLoading && <span className='spinner-border spinner-border-sm'/>} LOGIN
                  </button>
                                </div>
                               
                            </form>
                        </div>                                       
                                </div>

                                <div id='tab-1' className={classNames('tab-content',{'current':active===1})}>
                                <div id="register-form"> 
                            <form
                                name="registerForm"
                                className="row register-form"
                                onSubmit={handleSubmit(onFormSubmit)}
                            >
                                {/* Form Input */}
                                <div id="input-name" className="col-md-12">
                                    <p>Your Name*</p>
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="form-control name"
                                        placeholder="Enter Your Name*"
                                       ref={register({required:"Name is required"})}
                                    />
                                    {errors.firstName && <p className='text-danger'>{errors.firstName.message}</p>}
                                </div>
                                {/* Form Input */}
                                <div id="input-email" className="col-md-12">
                                    <p>Your Email*</p>
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control email"
                                        placeholder="Enter Your Email*"
                                        ref={register({required:"Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                          }})}
                                    />
                                    {errors.email && <p className='text-danger'>{errors.email.message}</p>}
                                </div>
                                {/* Form Input */}
                                <div id="input-phone" className="col-md-12">
                                    <p>Your Phone Number*</p>
                                    <PhoneInput
                                            country={'ae'}
                                            value={phone}
                                            onChange={phone => setPhone(phone) }                                            
                                            inputClass="form-control"
                                             />
                                    {error && <p className='text-danger'>{error}</p>}
                                </div>
                                <div id="input-password" className="col-md-12">
                                    <p>Your Password*</p>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control name"
                                        placeholder="Enter Your Password*"
                                       ref={register({required:"Password is required",
                                       minLength: {
                                        value: 4,
                                        message: "Password must have at least 4 characters"
                                      }})}
                                    />
                                    {errors.password && <p className='text-danger'>{errors.password.message}</p>}
                                </div>
                                <div id="input-cpassword" className="col-md-12">
                                    <p>Confirm Password*</p>
                                    <input
                                        type="password"
                                        name="cpassword"
                                        className="form-control name"
                                        placeholder="Repeat Your Password*"
                                        ref={register({
                                            validate: value =>
                                              value === password.current || "The passwords do not match"
                                          })}
                                    />
                                    {errors.cpassword && <p className='text-danger'>{errors.cpassword.message}</p>}
                                </div>
                                {/* Form Button */}
                                <div className="col-md-12 form-btn">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-primary mt-2"
                                    >
                                     {auth.isLoading && <span className='spinner-border spinner-border-sm'/>}   Register Now
                  </button>
                                </div>
                               
                            </form>
                        </div>
                                </div>
                               

                            </div>

                        </div>
                        </div></section>
                        
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
