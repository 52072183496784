import React, { useState } from "react";
// import Link from "next/link";
// import Router from "next/router";
const Faqs3 = () => {
  const [active, setActive] = useState(1);
  const activeLi = (value) => (active === value ? "current" : "");
  return (
    <section id="faqs-1" className=" faq3 faqs-section division block">
      <div className="container">
        <div className="row">
          {/* TABS NAVIGATION */}
          <div className="col-lg-4 head-2">
            <div className="tabs-nav tabs-nav1 clearfix">
              <ul className="tabs-1 tabs-2">
                {/* TAB-1 LINK */}
                <li
                  className={`tab-link ${activeLi(1)}`}
                  onClick={() => setActive(1)}
                  data-tab="tab-1"
                >
                 
                  <div className="tab-link-txt tab-link-txt1">
                    <h5 className="h5-xs">NBD</h5>
                    
                  </div>
                </li>
                {/* TAB-2 LINK */}
                <li
                  className={`tab-link ${activeLi(2)}`}
                  onClick={() => setActive(2)}
                  data-tab="tab-2"
                >
                    <div className="tab-link-txt tab-link-txt1">
                    <h5 className="h5-xs">HSBS</h5>
                    
                  </div>
                </li>
                {/* TAB-3 LINK */}
                <li
                  className={`tab-link ${activeLi(3)}`}
                  onClick={() => setActive(3)}
                  data-tab="tab-3"
                >
                    <div className="tab-link-txt tab-link-txt1">
                    <h5 className="h5-xs">BPN</h5>
                    
                  </div>
                </li>
                {/* TAB-4 LINK */}
                <li
                  className={`tab-link ${activeLi(4)}`}
                  onClick={() => setActive(4)}
                  data-tab="tab-4"
                >
                    <div className="tab-link-txt tab-link-txt1">
                    <h5 className="h5-xs">DIB</h5>
                    
                  </div>
                </li>
                <li
                  className={`tab-link ${activeLi(5)}`}
                  onClick={() => setActive(5)}
                  data-tab="tab-4"
                >
                    <div className="tab-link-txt tab-link-txt1">
                    <h5 className="h5-xs">ENBD</h5>
                    
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* END TABS NAVIGATION */}
          {/* TABS CONTENT */}
          <div className="col-lg-8">
            <div className="tabs-content">
              {/* TAB-1 CONTENT */}
              <div id="tab-1" className={`tab-content ${activeLi(1)}`}>
                {/* CATEGORY TITLE */}
                
                <div className="container">
        <div className="row">
        <h5>NBD EMI PLANS</h5>
          {/* HERO TEXT */}
          <div className="card">
           
                        <div className="order-list">
                        
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Months</th>
                                        <th className="order-list__column-quantity">Monthly EMI</th>
                                        <th className="order-list__column-total">Overall Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                   
                                </tbody>
                               
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">3</th>
                                            <td className="order-list__column-quantity">AED 8333 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">6</th>
                                            <td className="order-list__column-quantity">AED 6000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">9</th>
                                            <td className="order-list__column-quantity">AED 3000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">12</th>
                                            <td className="order-list__column-quantity">AED 2000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                    </tbody>
                              
                                
                            </table>
                        </div>
                    </div>
        </div>
        {/* End row */}
      </div>
                
                {/* QUESTION #1 */}
                
              </div>
              {/* END TAB-1 CONTENT */}
              {/* TAB-2 CONTENT */}
              <div id="tab-2" className={`tab-content ${activeLi(2)}`}>
                {/* CATEGORY TITLE */}
                <div className="container">
        <div className="row">
        <h5>HSBS EMI PLANS</h5>
          {/* HERO TEXT */}
          <div className="card">
           
                        <div className="order-list">
                        
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Months</th>
                                        <th className="order-list__column-quantity">Monthly EMI</th>
                                        <th className="order-list__column-total">Overall Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                   
                                </tbody>
                               
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">3</th>
                                            <td className="order-list__column-quantity">AED 8333 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">6</th>
                                            <td className="order-list__column-quantity">AED 6000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">9</th>
                                            <td className="order-list__column-quantity">AED 3000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">12</th>
                                            <td className="order-list__column-quantity">AED 2000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                    </tbody>
                              
                                
                            </table>
                        </div>
                    </div>
        </div>
        {/* End row */}
      </div>
                
                {/* END QUESTION #8 */}
              </div>
              {/* END TAB-2 CONTENT */}
              {/* TAB-3 CONTENT */}
              <div id="tab-3" className={`tab-content ${activeLi(3)}`}>
              <div className="container">
        <div className="row">
        <h5>BPN EMI PLANS</h5>
          {/* HERO TEXT */}
          <div className="card">
           
                        <div className="order-list">
                        
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Months</th>
                                        <th className="order-list__column-quantity">Monthly EMI</th>
                                        <th className="order-list__column-total">Overall Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                   
                                </tbody>
                               
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">3</th>
                                            <td className="order-list__column-quantity">AED 8333 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">6</th>
                                            <td className="order-list__column-quantity">AED 6000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">9</th>
                                            <td className="order-list__column-quantity">AED 3000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">12</th>
                                            <td className="order-list__column-quantity">AED 2000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                    </tbody>
                              
                                
                            </table>
                        </div>
                    </div>
        </div>
        {/* End row */}
      </div> 
              </div>
              {/* END TAB-3 CONTENT */}
              {/* TAB-4 CONTENT */}
              <div id="tab-4" className={`tab-content ${activeLi(4)}`}>
                {/* CATEGORY TITLE */}
                <div className="container">
        <div className="row">
        <h5>DIB EMI PLANS</h5>
          {/* HERO TEXT */}
          <div className="card">
           
                        <div className="order-list">
                        
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Months</th>
                                        <th className="order-list__column-quantity">Monthly EMI</th>
                                        <th className="order-list__column-total">Overall Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                   
                                </tbody>
                               
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">3</th>
                                            <td className="order-list__column-quantity">AED 8333 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">6</th>
                                            <td className="order-list__column-quantity">AED 6000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">9</th>
                                            <td className="order-list__column-quantity">AED 3000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">12</th>
                                            <td className="order-list__column-quantity">AED 2000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                    </tbody>
                              
                                
                            </table>
                        </div>
                    </div>
        </div>
        {/* End row */}
      </div>
                
              </div>
              {/* END TAB-4 CONTENT */}
               {/* TAB-4 CONTENT */}
               <div id="tab-4" className={`tab-content ${activeLi(5)}`}>
                {/* CATEGORY TITLE */}
                <div className="container">
        <div className="row">
        <h5>ENBD EMI PLANS</h5>
          {/* HERO TEXT */}
          <div className="card">
           
                        <div className="order-list">
                        
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Months</th>
                                        <th className="order-list__column-quantity">Monthly EMI</th>
                                        <th className="order-list__column-total">Overall Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                   
                                </tbody>
                               
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">3</th>
                                            <td className="order-list__column-quantity">AED 8333 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">6</th>
                                            <td className="order-list__column-quantity">AED 6000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">9</th>
                                            <td className="order-list__column-quantity">AED 3000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2">12</th>
                                            <td className="order-list__column-quantity">AED 2000 @ No Cost</td>
                                            <td className="order-list__column-total">₹24999</td>
                                        </tr>
                                    </tbody>
                              
                                
                            </table>
                        </div>
                    </div>
        </div>
        {/* End row */}
      </div>
                
              </div>
              {/* END TAB-4 CONTENT */}
            </div>
          </div>
          {/* END TABS CONTENT */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </section>
  );
};

export default Faqs3;
