import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";
// import {authHeader} from '../_heleper/auth-header'

const headerApi = {  

  getDepartments: () => {
    const reqOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json" },     
    };
    return fetch(`${url}/common/cat-tree-list`,reqOptions).then(response=>response.json());
  },
  getHeaderNavs: () => {
    const reqOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json" },     
    };
    return fetch(`${url}/common/cat-tree-list-column`,reqOptions).then(responseHandler);
  },
  
};

export default headerApi;
