// react
import React from 'react';
import { Link } from 'react-router-dom';

export default function BlockBanner(props) {
   
    const image = props.data?.[props.name]?.url ??""
    const redirect = props.data?.[props.name]?.link ??"/"
    return (
        <div className="block block-banner">
            <div className="container my-5">
                <Link to={redirect} className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: `url("${image}")` }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: `url("${image}")` }}
                    />
                    {/* <div className="block-banner__title">
                    IFZA Dubai Free Zone 
                        <br className="block-banner__mobile-br" />
                       
                    </div>
                    <div className="block-banner__text">
                    We will set up your IFZA free zone company
                    </div> */}
                    {/* <div className="block-banner__button">
                        <span className="btn btn-sm btn-primary">More Info</span>
                    </div> */}
                </Link>
            </div>
        </div>
    );
}
