// eslint-disable-next-line
export default [
    {
        type: 'link',
        label: 'Home',
        url: '/',
        // children: [
        //     { type: 'link', label: 'Home 1', url: '/' },
        //     { type: 'link', label: 'Home 2', url: '/home-two' },
        // ],
    },

    {
        type: 'link',
        label: 'Categories',
        url: '',
        children: [
            {
                type: 'link',
                label: 'Free Zone',
                url: '',
                children: [
                    {
                        type: 'link',
                        label: 'Abu Dhabi',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Khalifa Industrial Zone Abu Dhabi (KIZAD)',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'MASDAR',
                                url: '',
                                
                            },
                            
                        ],
                    },
                    {
                        type: 'link',
                        label: 'Dubai',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'DIFC',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'DTEC',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Commercity',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Internet City',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai South (DWC)',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'IFZA',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Silicon Oasis',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Media City',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Airport Free Zone',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Knowledge Village',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Science Park',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Studio City',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Techno Park',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Dubai Production City',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'DMCC',
                                url: '',
                                
                            },
                            
                            
                            
                        ],
                    },
                    {
                        type: 'link',
                        label: 'Sharjah',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'SHAMS',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Sharjah Publishing City',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'SAIF',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Hamriya Free Zone',
                                url: '',
                                
                            },
                            
                        ],
                    },

                    {
                        type: 'link',
                        label: 'Ajman',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Ajman Free Zone',
                                url: '',
                                
                            },
                            {
                                type: 'link',
                                label: 'Ajman Media City Free Zone',
                                url: '',
                                
                            },
                            
                        ],
                    },

                    {
                        type: 'link',
                        label: 'Fujairah',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Creative City Fujairah',
                                url: '',
                                
                            },
                           
                            
                        ],
                    },

                    {
                        type: 'link',
                        label: 'Ras Al Khaimah',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Ras Al Khaimah Economic Zone (RAKEZ)',
                                url: '',
                                
                            },
                           
                            
                        ],
                    },
                    {
                        type: 'link',
                        label: 'Umm Al Quwain',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Umm Al Quwain Free Zone',
                                url: '',
                                
                            },
                           
                            
                        ],
                    },
                    




                ],
            },
            {
                type: 'link',
                label: 'Main Land',
                url: '',
                children: [
                    {
                        type: 'link',
                        label: 'Abu Dhabi',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },
                   



                    {
                        type: 'link',
                        label: 'Dubai',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },



                    {
                        type: 'link',
                        label: 'Sharjah',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },




                    {
                        type: 'link',
                        label: 'Ajman',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },




                    {
                        type: 'link',
                        label: 'Fujairah',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },




                    {
                        type: 'link',
                        label: 'Ras Al Khaimah',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },

                    {
                        type: 'link',
                        label: 'Umm Al Quwain',
                        url: '',
                        children: [
                            {
                                type: 'link',
                                label: 'Commercial',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Trading', url: '' },
                                    { type: 'link', label: 'General Trading', url: '' },
                                    { type: 'link', label: 'Industrial', url: '' },
                                    { type: 'link', label: 'Manufacturing', url: '' },
                                    { type: 'link', label: 'Real Estate', url: '' },
                                ],
                            },
                            {
                                type: 'link',
                                label: 'Professional',
                                url: '',
                                children: [
                                    { type: 'link', label: 'Media', url: '' },
                                    { type: 'link', label: 'Consultancy', url: '' },
                                    { type: 'link', label: 'E-Commerce', url: '' },
                                    
                                    
                                ],
                            },

                            {
                                type: 'link',
                                label: 'Holding',
                                url: '',
                            },
                        ],
                    },

                ],
            },
            {
                type: 'link',
                label: 'Freelance',
                url: '',
                children: [
                    { type: 'link', label: 'Freelance1', url: '' },
                    { type: 'link', label: 'Freelance2', url: '' },
                    { type: 'link', label: 'Freelance3', url: '' },
                    { type: 'link', label: 'Freelance4', url: '' },
                    
                ],
            },
        ],
    },

    // {
    //     type: 'link',
    //     label: 'Shop',
    //     url: '/shop/category-grid-3-columns-sidebar',
    //     children: [
    //         {
    //             type: 'link',
    //             label: 'Shop Grid',
    //             url: '/shop/category-grid-3-columns-sidebar',
    //             children: [
    //                 { type: 'link', label: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
    //                 { type: 'link', label: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
    //                 { type: 'link', label: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
    //             ],
    //         },
    //         { type: 'link', label: 'Shop List', url: '/shop/category-list' },
    //         { type: 'link', label: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
    //         {
    //             type: 'link',
    //             label: 'Product',
    //             url: '/shop/product-standard',
    //             children: [
    //                 { type: 'link', label: 'Product', url: '/shop/product-standard' },
    //                 { type: 'link', label: 'Product Alt', url: '/shop/product-columnar' },
    //                 { type: 'link', label: 'Product Sidebar', url: '/shop/product-sidebar' },
    //             ],
    //         },
    //         { type: 'link', label: 'Cart', url: '/shop/cart' },
    //         { type: 'link', label: 'Checkout', url: '/shop/checkout' },
    //         { type: 'link', label: 'Order Success', url: '/shop/checkout/success' },
    //         { type: 'link', label: 'Wishlist', url: '/shop/wishlist' },
    //         { type: 'link', label: 'Compare', url: '/shop/compare' },
    //         { type: 'link', label: 'Track Order', url: '/shop/track-order' },
    //     ],
    // },

    {
        type: 'link',
        label: 'Account',
        url: '/account1',
        children: [

            // { type: 'link', label: 'Login', url: '/account/login' },
            // { type: 'link', label: 'Dashboard', url: '/account/dashboard' },
            // { type: 'link', label: 'Edit Profile', url: '/account/profile' },
            // { type: 'link', label: 'Order History', url: '/account/orders' },
            // { type: 'link', label: 'Order Details', url: '/account/orders/5' },
            // { type: 'link', label: 'Address Book', url: '/account/addresses' },
            // { type: 'link', label: 'Edit Address', url: '/account/addresses/5' },
            // { type: 'link', label: 'Change Password', url: '/account/password' },
        ],
    },

    // {
    //     type: 'link',
    //     label: 'Blog',
    //     url: '/blog/category-classic',
    //     children: [
    //         { type: 'link', label: 'Blog Classic', url: '/blog/category-classic' },
    //         { type: 'link', label: 'Blog Grid', url: '/blog/category-grid' },
    //         { type: 'link', label: 'Blog List', url: '/blog/category-list' },
    //         { type: 'link', label: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //         { type: 'link', label: 'Post Page', url: '/blog/post-classic' },
    //         { type: 'link', label: 'Post Without Sidebar', url: '/blog/post-full' },
    //     ],
    // },

    // {
    //     type: 'link',
    //     label: 'Pages',
    //     url: '/site/about-us',
    //     children: [
    //         { type: 'link', label: 'About Us', url: '/site/about-us' },
    //         { type: 'link', label: 'Contact Us', url: '/site/contact-us' },
    //         { type: 'link', label: 'Contact Us Alt', url: '/site/contact-us-alt' },
    //         { type: 'link', label: '404', url: '/site/not-found' },
    //         { type: 'link', label: 'Terms And Conditions', url: '/site/terms' },
    //         { type: 'link', label: 'FAQ', url: '/site/faq' },
    //         { type: 'link', label: 'Components', url: '/site/components' },
    //         { type: 'link', label: 'Typography', url: '/site/typography' },
    //     ],
    // },

    {
        type: 'button',
        label: 'Currency',
        children: [
            { type: 'button', label: '€ Euro', data: { type: 'currency', code: 'EUR' } },
            { type: 'button', label: '£ Pound Sterling', data: { type: 'currency', code: 'GBP' } },
            { type: 'button', label: '$ US Dollar', data: { type: 'currency', code: 'USD' } },
            { type: 'button', label: '₽ Russian Ruble', data: { type: 'currency', code: 'RUB' } },
        ],
    },

    {
        type: 'button',
        label: 'Language',
        children: [
            { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
            { type: 'button', label: 'Arabic', data: { type: 'language', locale: 'ru' } },
        ],
    },
];
