import React from "react";
import {Link} from 'react-router-dom'

const Demo1Banner5 = () => {
    return (
        <section
            id="banner-5"
            className="bg-whitesmoke bg-05 wide-60 banner-section division"
        >
            <div className="container">
                <div className="row">
                    {/* BANNER TEXT */}
                    <div className="col-md-4">
                        <div className="banner-5-txt">
                            {/* Icon */}
                            <img src="images/app.jpg" alt="banner-icon" />
                            {/* Text */}
                            <div className="b5-txt">
                                {/* Title */}
                                <h4 className="h4-xs">Apple Store</h4>
                                {/* Text */}
                                <p>
                                Everything about iOS is designed to be easy. </p>
                                {/* Button */}
                                <Link to="#"
                                    className="btn btn-sm btn-primary">
                                        Download 
                                    
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* END BANNER TEXT */}
                    {/* BANNER TEXT */}
                    <div className="col-md-4">
                        <div className="banner-5-txt">
                            {/* Icon */}
                            <img src="images/app1.jpg" alt="banner-icon" />
                            {/* Text */}
                            <div className="b5-txt">
                                {/* Title */}
                                <h4 className="h4-xs">Google Play store</h4>
                                {/* Text */}
                                <p>
                                Everything about iOS is designed to be easy.
                                </p>
                                {/* Button */}
                                <Link to="#"
                                     className="btn btn-sm btn-primary">
                                    Download 
                                    
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* END BANNER TEXT */}
                     {/* BANNER TEXT */}
                     <div className="col-md-4">
                        <div className="banner-5-txt">
                            {/* Icon */}
                            <img src="images/app2.jpg" alt="banner-icon" />
                            {/* Text */}
                            <div className="b5-txt">
                                {/* Title */}
                                <h4 className="h4-xs">App Gallery</h4>
                                {/* Text */}
                                <p>
                                Everything about iOS is designed to be easy. </p>
                                {/* Button */}
                                <Link to="#"
                                   className="btn btn-sm btn-primary">
                                        Download 
                                   
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* END BANNER TEXT */}
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default Demo1Banner5;
