// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
// import PostCard from '../shared/PostCard';
import StroykaSlick from '../shared/StroykaSlick';
import TestimonialSliderCon from "../Demo1/TestimonialSliderCon";
const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockPosts extends Component {
    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { title, layout, posts } = this.props;

        const testimonialsection = posts.map((post) => <TestimonialSliderCon key={post._id} post={post}/>);

        return (
            <div className={`block block-posts  wide-20 block-posts--layout--${layout}`} data-layout={layout}>
                <div className="container">
                    <BlockHeader
                        arrows
                        title={title}
                        onNext={this.handleNextClick}
                        onPrev={this.handlePrevClick}
                    />

                    <div className="block-posts__slider">
                        <StroykaSlick
                            ref={this.setSlickRef}
                            {...slickSettings[layout]}
                        >
                        {testimonialsection}
                       
                           
                        </StroykaSlick>
                    </div>
                </div>
            </div>
        );
    }
}

BlockPosts.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
    posts: PropTypes.array,
};

BlockPosts.defaultProps = {
    layout: 'list-sm',
    posts: [],
};
