
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function Currency(props) {
    const { value, currency, currentCurrency } = props;
    const { symbol, rate } = currency || currentCurrency;
    const price = (1*value*rate).toFixed(2)

    return <React.Fragment>{`${symbol} ${price}`}</React.Fragment>;
}

Currency.propTypes = {
    /** price value */
    value: PropTypes.any.isRequired,
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
