import { REGISTER_CLOSE, REGISTER_OPEN, TOUR_CLOSE, TOUR_OPEN, TOUR_POPUP_CLOSE, TOUR_POPUP_OPEN } from "./registerActionTypes";

const initialState = {
  open: false,
  tour: false,
  popup: true,
};

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_OPEN:
      return {
        ...state,  open: true,
      };
    case REGISTER_CLOSE:
      return {
        ...state, open: false,
      };
    case TOUR_OPEN:
        return {
            ...state, tour: true, popup:false
        };
    case TOUR_CLOSE:
      return {
        ...state,  tour: false,
      };
    case TOUR_POPUP_OPEN:
        return {
          ...state,  popup: true,
        };
    case TOUR_POPUP_CLOSE:
        return {
          ...state,  popup: false,
        };
    default:
      return state;
  }
}
