import React from "react";
import { Accordion } from "react-bootstrap";
const Homefaq = ({ faq }) => {
  // let sort = 2;
  // const faqsection = posts.map((post) => <CategoriesListCategories4 key={post._id} post={post} />);

  return (
    <section
      id="about-2"
      className="wide-60 bg-whitesmoke about-section division"
    >
      <div className="container">
        <div className="row d-flex align-items-center">
          {/* ABOUT IMAGE */}
          <div className="col-md-5 col-lg-6">
            <div className="img-block pc-25 mb-40">
              <img
                className="img-fluid"
                src="images/image-01.jpg"
                alt=""
              />
            </div>
          </div>
          {/* ABOUT TEXT */}
          <div className="col-md-7 col-lg-6">
            <h4>Faq</h4>
            <Accordion defaultActiveKey="0">
              {faq.length > 0 &&
                faq.map((f, i) => (
                  <Accordion.Item key={i} eventKey={i}>
                    <Accordion.Header>{f.qus}</Accordion.Header>
                    <Accordion.Body>{f.ans}</Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          {/* END ABOUT TEXT */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </section>
  );
};

export default Homefaq;
