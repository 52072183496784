// react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CartIndicator from './IndicatorCart';
import CompareIndicator from './IndicatorCompare'
import Departments from './Departments';
import Indicator from './Indicator';
import IndicatorAccount from './IndicatorAccount';
import IndicatorSearch from './IndicatorSearch';
import NavLinks from './NavLinks';
// import NavLinks1 from './NavLinks1';
import { Heart20Svg, LogoSmallSvg } from '../../svg';

function NavPanel(props) {
    const { layout, wishlist,  dept, hdata } = props;

    let logo = null;
    let departments = null;
    let searchIndicator;

    if (layout === 'compact') {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/"><LogoSmallSvg /></Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === 'default') {
        departments = (
            <div className="nav-panel__departments">
                <Departments dept={dept}/>
            </div>
        );
    }

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row">
                    {logo}
                    {departments}

                    <div className="nav-panel__nav-links nav-links">
                        <NavLinks hdata={hdata} />
                    </div>

                    <div className="nav-panel__indicators">
                        {searchIndicator}

                       
                        {/* <NavLinks1 /> */}
                        <CompareIndicator/>
                        {/* <Indicator url="/shop/compare" value={compare} name1={"compare"}  icon={<Compare16Svg /> } /> */}
                        <Indicator url="/shop/wishlist" value={wishlist} name1={"Wishlist"} icon={<Heart20Svg />} />
                         
                        <CartIndicator />

                        <IndicatorAccount  />
                    </div>
                </div>
            </div>
        </div>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist.length,
    compare: state.compare.length,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
