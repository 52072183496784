
import { SEARCH_ITEMS_ADD, SEARCH_ITEMS_RESET } from "./searchActionTypes";

const initialState = {};

export function searchReducer(state= initialState, action){
    switch(action.type){
        case SEARCH_ITEMS_ADD:
            return action.payload;        

        case SEARCH_ITEMS_RESET:
            return {}

        default:
            return state;
    }
}