import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";
// import { authHeader } from "../_heleper/auth-header";


// common service for parent child relationship

const init = {default:"none"}

const getCategories = (data={}) => {
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/cat-list`,reqOptions).then(responseHandler)
}

const getEmirates = (data={},type='none') => {
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/emi-list?type=${type}`,reqOptions).then(responseHandler)
}

const getZones = (data=init) => {
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/zone-list`,reqOptions).then(responseHandler)
}

const getLicenses = (data=init) => {
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/lic-list`,reqOptions).then(responseHandler)
}

const getSubLicenses = (data=init,q="All") => {  
  if(data.zone===null || data.zone.length === 0) delete data.zone;  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/sublic-list?status=${q}`,reqOptions).then(responseHandler)
}

const getSubLicenseGroups = (data=init,q="All") => {  
  if(data.zone===null || data.zone.length === 0) delete data.zone;  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/sublic-group-list?status=${q}`,reqOptions).then(responseHandler)
}

const getActivityGroups = (data=init,q="All") => {
  if(data.zone===null || data.zone.length === 0) delete data.zone;
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/act-group-list?status=${q}`,reqOptions).then(responseHandler)
}

const getActivities = (data=init,q="All") => {
  if(data.zone===null || data.zone.length === 0) delete data.zone;
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/act-list?status=${q}`,reqOptions).then(responseHandler)
}

const getSubActivities = (data=init,q="All") => {
  if(data.zone===null || data.zone.length === 0) delete data.zone;
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/subact-list?status=${q}`,reqOptions).then(responseHandler)
}



const getSubActivitySearch = (data,skip,limit) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/subact-search?skip=${skip}&limit=${limit}`,reqOptions).then(responseHandler)
}

const getNorthActivitySearch = (data,skip,limit) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/northern-subact-search?skip=${skip}&limit=${limit}`,reqOptions).then(responseHandler)
}

const getNorthernCompared = (data) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/northern-compare`,reqOptions).then(responseHandler)
}

const getPriceWithSubActList = (data) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/price-subact-list`,reqOptions).then(responseHandler)
}

const getSearchOptions = (data) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/subact-search-options`,reqOptions).then(responseHandler)
}

const getNorthOptions = (data) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/north-search-options`,reqOptions).then(responseHandler)
}

const getPriceList = (data=init) => { 
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/price-list`,reqOptions).then(responseHandler)
}

const getPrice = (data=init) => { 
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/price`,reqOptions).then(responseHandler)
}

const flSearchOptions = (data) => {  
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/fl-search-options`,reqOptions).then(responseHandler)
}

const getFlPriceList = (data=init) => { 
  const reqOptions = {method:"POST", headers:{"Content-Type": "application/json"}, body:JSON.stringify(data)}
      return fetch(`${url}/common/fl-price-list`,reqOptions).then(responseHandler)
}

export const commonApi = {
  getCategories,getEmirates,getZones,getLicenses,getSubLicenses,getSubLicenseGroups,getActivityGroups,
  getActivities,getSubActivities,getPriceList,getPrice,getSubActivitySearch,getPriceWithSubActList,
  getNorthActivitySearch,getNorthernCompared,getSearchOptions,getFlPriceList,getNorthOptions,
  flSearchOptions,
}