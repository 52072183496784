import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import version from './version';
import wishlistReducer from './wishlist';
import userReducer from './user';
import addonReducer from './addons';
import orderReducer from './order';
import searchReducer from './search';
import registerReducer from './register';

export default combineReducers({
    version: (state = version) => state,
    auth: userReducer,
    appview:orderReducer,
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    regipop: registerReducer,
    wishlist: wishlistReducer,
    addon: addonReducer,
    search:searchReducer,
});
